import * as Styles from "./styles";
import { ReactComponent as NewTaskIcon } from "./icons/new-task.svg";
import { Button } from "antd";
// import { PickItem } from "../../../../../../common/models/PickItem";
import { useState, useEffect } from "react";
import { ReactComponent as CardLoaderIcon } from "../../../../assets/icons/card_loader.svg";
import { useNavigate } from "react-router-dom";
import PickupTote from "../pickup-tote/PickupTote";
// import { ReactComponent as Toast } from "./icons/info.svg";

export interface NewTaskProps {
  pickItemsData: any[];
  PickItemLoading: boolean;
  taskCount: any;
  pickStationsData: any[];
}

export default function NewTask({
  pickItemsData,
  PickItemLoading,
  taskCount,
  pickStationsData
}: NewTaskProps) {
  const [startLoading, setStartLoading] = useState(false);
  const [stationData, setStationData] = useState([]);
  const [clickStatus, setClickStatus] = useState(false);
  const [clickData, setClickData] = useState(false);
  const hideShimmer = pickItemsData?.length === 1;
  //const taskCount = pickItemsData?.length;
  const navigate = useNavigate();
  useEffect(() => {
    getStationsData();
  }, [pickStationsData]);
  const getStationsData = () => {
    let out:any = [];
    if(pickStationsData.length > 0)
    {
    pickStationsData[0].gates.map((val:any) => {
      const out_str = `${val.slot["x"]},${val.slot["y"]},${val.slot["z"]}`;
      out.push(out_str);
    })
    /* out.sort((a:any,b:any) => {
    return b[0]-a[0]
    }); */
    setStationData(out.reverse());
    }
  };
  const clickStart = (clickedItem: any) => {
    setStartLoading(true);
    setClickStatus(true);
    setClickData(clickedItem);
    /* setTimeout(() => {
      setStartLoading(false);
      navigate(`/pickup_tote`, {
        state: {
          clickedItem:  clickedItem,
            pickStationsData: stationData
          }
      });
    }, 1000); */
  };

  const sortedPickItem = pickItemsData?.sort((a, b) => {
    if (a.creationDate < b.creationDate) {
      return 1;
    }
    if (a.creationDate > b.creationDate) {
      return -1;
    }
    return 0;
  });

  return (
    clickStatus ? 
      <PickupTote pickItemsData={pickItemsData} pickStationsData={stationData} />
    :
    <Styles.Container hide={hideShimmer}>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>

      <Styles.Parent>
        {sortedPickItem?.map((item: any, index) => {
          return (
            <Styles.Child key={index} PickItemLoading={PickItemLoading}>
              <Styles.ChildLoad>
                <CardLoaderIcon />
              </Styles.ChildLoad>
              <Styles.Header>
                <Styles.HeaderText>New Task Available !</Styles.HeaderText>
              </Styles.Header>
              <Styles.Content className="enter">
                <NewTaskIcon />
              </Styles.Content>
              <Styles.Footer>
                <Button
                  onClick={() => clickStart(sortedPickItem)}
                  loading={startLoading}
                >
                  {startLoading === true ? "Loading..." : "START"}
                </Button>
              </Styles.Footer>
            </Styles.Child>
          );
        })}
        <Styles.SubMessage>
          -{" "}
          {taskCount !== undefined && taskCount < 10
            ? ("0" + `${taskCount}`).slice(-2)
            : taskCount}{" "}
          tasks are in queue -
        </Styles.SubMessage>
      </Styles.Parent>

      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
    </Styles.Container>
  );
}
