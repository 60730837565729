import * as Styles from "./styles";
import { Modal, Button } from "antd";

export interface OkPopupProps {
  visible: boolean;
  isCloseOkPopup: () => void;
  isRestart: () => void;
  isCancel: () => void;
  errorMessage: string
}

export default function OkPopup({
  visible,
  isCloseOkPopup,
  isRestart,
  isCancel,
  errorMessage
}: OkPopupProps) {
  return (
    <Styles.Container>
      <Modal
        wrapClassName="operator"
        open={visible}
        onOk={isCloseOkPopup}
        onCancel={isCancel}
        closable={true}
        footer={[
          <Button key="back" onClick={isRestart}>
            Restart
          </Button>,
          <Button key="submit" type="primary" onClick={isCloseOkPopup}>
            Retry
          </Button>
        ]}
      >
        <Styles.Head>Oops !!</Styles.Head>
        <Styles.Content>
        {errorMessage ? 
          errorMessage
          :
          "Sorry, something went wrong, please retry again or restart the process."
          }
        </Styles.Content>
      </Modal>
    </Styles.Container>
  );
}
