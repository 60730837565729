import { Button } from "antd";
import * as Styles from "./styles";
import { useState } from "react";
import RestartPopup from "../restart-popup/RestartPopup";
import FlagPopup from "../flag-popup/FlagPopup";
import { ReactComponent as PickupIcon } from "./icons/pickup.svg";
import { ReactComponent as ImageIcon } from "./icons/product.svg";
import { formatDistance } from "date-fns";
import axios from "../../../../../../common/api/axios";
import { message } from "antd";
import { ReactComponent as Toast } from "../../../../../../assets/icons/toast-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";

export interface OrderDetailsProps {}

export default function OrderDetails({}: OrderDetailsProps) {
  const [isRestartPopup, setIsRestartPopup] = useState(false);
  const [isFlagPopup, setIsFlagPopup] = useState(false);
  const [qCount, setQCount] = useState(0);
  const [doneLoading, setDoneLoading] = useState(false);
  const [doneDisable, setDoneDisable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const navigate = useNavigate();
  const locatedItem = location?.state;

  const doneErrorToastMessage = () => {
    messageApi.open({
      content: "Request failed with status code 500",
      type: "error",
      icon: <></>,
      className: "error",
      style: {
        marginTop: "85vh",
      },
    });
  };

  const noTaskToastMessage = () => {
    messageApi.open({
      content: "Pick-task Complete !",
      icon: <Toast />,
      className: "order",
      style: {
        marginTop: "85vh",
      },
    });
  };

  // POST Pick Items for order fulfillment
  const getPickItemsData = async (id: string) => {
    try {
      setDoneLoading(true);
      const res = await axios.put(`/PickStation/signalPick/${id}`, null, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setDoneLoading(false);
        setDoneDisable(true);
        noTaskToastMessage();
        setTimeout(() => {
          navigate("/task-id");
        }, 2000);
      }
    } catch (error: any) {
      setDoneLoading(true);
      setTimeout(() => {
        setDoneLoading(false);
      }, 1000);
      doneErrorToastMessage();
    }
  };

  const isOpenRestartPopup = () => {
    setIsRestartPopup(true);
  };

  const isCloseRestartPopup = () => {
    setIsRestartPopup(false);
  };

  const isOpenFlagPopup = () => {
    setIsFlagPopup(true);
  };

  const isCloseFlagPopup = () => {
    setIsFlagPopup(false);
  };

  const dateTimeFormat = (time: string) => {
    const distance = formatDistance(Date.now(), new Date(time), {
      addSuffix: true,
    });
    const firstWord = distance.replace(/in/g, "");
    const finalWord = firstWord.replace(/about/g, "");
    const formatted = finalWord.replace(/mutes/g, "Mins");
    const one = formatted.replace(/mute/g, "Minute");
    const two = one.replace(/ago/g, "");
    return two;
  };

  return (
    <>
      <Styles.Container className="order-detail">
        <>
          <Styles.TaskBar>
            <Styles.Item>
              <span>Task ID:</span>
              <span>
                {locatedItem?.pickTask.binId}_{locatedItem?.pickTask.skuId}_
                {locatedItem?.pickTask.quantity}
              </span>
            </Styles.Item>
            <Styles.Item>
              <span>Order ID:</span>
              <span>{locatedItem?.pickTask.orderReferenceId}</span>
            </Styles.Item>
            <Styles.Item>
              <span>Placed @</span>
              {locatedItem?.creationDate !== undefined && (
                <span>{dateTimeFormat(locatedItem?.creationDate)} Ago</span>
              )}
            </Styles.Item>
          </Styles.TaskBar>
          <Styles.Content>
            <Styles.OrderCard
              disable={qCount === locatedItem?.pickTask.quantity}
            >
              <Styles.ImgContainer>
                <ImageIcon />
              </Styles.ImgContainer>
              <Styles.IDContainer>
                <Styles.Labels>Item Name:</Styles.Labels>
                <Styles.ItemName>Presto Kitchen Towel </Styles.ItemName>
              </Styles.IDContainer>
              <Styles.IDContainer>
                <Styles.QContainer>
                  <div>
                    <Styles.Labels>Qty Count</Styles.Labels>
                    <Styles.ItemName className="count">
                      {qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount}
                    </Styles.ItemName>
                  </div>
                  <div>
                    <Styles.Labels>Qty Needed</Styles.Labels>
                    <Styles.ItemName className="need">
                      {locatedItem?.pickTask.quantity !== undefined &&
                      locatedItem?.pickTask.quantity < 10
                        ? ("0" + `${locatedItem?.pickTask.quantity}`).slice(-2)
                        : locatedItem?.pickTask.quantity}
                    </Styles.ItemName>
                  </div>
                  <div>
                    <Styles.Labels>Exp Date</Styles.Labels>
                    <Styles.ItemName className="exp">-NA-</Styles.ItemName>
                  </div>
                </Styles.QContainer>
              </Styles.IDContainer>
              <Styles.IDContainer>
                <Styles.Labels>SKU ID:</Styles.Labels>
                <Styles.ItemName>{locatedItem?.pickTask.skuId}</Styles.ItemName>
              </Styles.IDContainer>
              <Styles.LeftButtonContainer>
                <Button
                  className="dec-btn"
                  disabled={qCount === 0}
                  onClick={() => setQCount(qCount - 1)}
                >
                  -1
                </Button>
                <Button
                  className="inc-btn"
                  onClick={() => setQCount(qCount + 1)}
                  disabled={qCount === locatedItem?.pickTask.quantity}
                >
                  +1
                </Button>
              </Styles.LeftButtonContainer>
            </Styles.OrderCard>
            <Styles.OrderDetail
              disable={qCount === locatedItem?.pickTask.quantity}
              doneDisabled={doneDisable}
            >
              <Styles.OrderContainer>
                <Styles.Head>Pickup Bin</Styles.Head>
                <Styles.DropContainer>
                  <Styles.Pickup
                    disable={
                      "6,1,2" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "5,1,2" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "4,1,2" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {" "}
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "3,1,2" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {" "}
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "2,1,2" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {" "}
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "1,1,2" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {" "}
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "6,1,1" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {" "}
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "5,1,1" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {" "}
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "4,1,1" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "3,1,1" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "2,1,1" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                  <Styles.Pickup
                    disable={
                      "1,1,1" ===
                      `${locatedItem?.pickTask.gate.x},${locatedItem?.pickTask.gate.y},${locatedItem?.pickTask.gate.z}`
                    }
                  >
                    {locatedItem?.pickTask.binId}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Pickup>
                </Styles.DropContainer>
              </Styles.OrderContainer>
              <Styles.OrderDivider />
              <Styles.OrderContainer>
                <Styles.Head>Drop Bin</Styles.Head>
                <Styles.DropContainer>
                  <Styles.Drop
                    disable={qCount === locatedItem?.pickTask.quantity}
                  >
                    OB0{qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount}
                    <Styles.PickIcon>
                      <PickupIcon />
                    </Styles.PickIcon>
                  </Styles.Drop>
                </Styles.DropContainer>
              </Styles.OrderContainer>
              <Styles.OrderDivider />
              {/* <Styles.OrderContainer>
              <Styles.Head>Drop Bin Details</Styles.Head>
              <Styles.Text>ID: OB001, Pick Station 1</Styles.Text>
            </Styles.OrderContainer> */}
              <Styles.OrderBtnContainer>
                {/* <Button className="flag-btn" onClick={isOpenFlagPopup}>
                Flag
              </Button>
              <Button className="restart-btn" onClick={isOpenRestartPopup}>
                Restart
              </Button> */}
                {locatedItem?.pickTask.orderReferenceId !== undefined &&
                  locatedItem?.pickTask.binId !== undefined &&
                  locatedItem?.pickTask.skuId !== undefined &&
                  locatedItem?.pickTask.quantity && (
                    <Button
                      className="done-btn"
                      onClick={() => getPickItemsData(locatedItem?.id)}
                      loading={doneLoading}
                    >
                      {doneLoading === true ? "Loading..." : "Done"}
                    </Button>
                  )}
              </Styles.OrderBtnContainer>
            </Styles.OrderDetail>
          </Styles.Content>
          <RestartPopup
            visible={isRestartPopup}
            isCloseRestartPopup={isCloseRestartPopup}
          />
          <FlagPopup
            visible={isFlagPopup}
            isCloseFlagPopup={isCloseFlagPopup}
          />
        </>
      </Styles.Container>
      <>{contextHolder}</>
    </>
  );
}
