import styled from "styled-components";

export const Container = styled.div`
  margin: 13px 0px;
  background: #f5f5f5;
`;

export const TaskBar = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  min-height: 60px;
  align-items: center;
  padding: 0px 10px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const TaskBarInner = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const Item = styled.div`
  background: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  span:first-child {
    color: #444444;
    margin-right: 5px;
  }
  span:last-child {
    color: #1f5454;
  }
  &.timestamp span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ItemText = styled.div<{ countReached?: boolean }>`
  background: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:last-child {
    text-align: right;
  }
  span:first-child {
    color: #444444;
    margin-right: 2px;
  }
  span:last-child {
    color: ${(props) => (props.countReached === false ? "#4d9a9a" : "#07A568")};
  }
`;
export const ItemEnd = styled.div`
  background: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: end;

  &:last-child {
    text-align: right;
  }
  span:first-child {
    color: #444444;
    margin-right: 2px;
  }
  span:last-child {
    color: #1f5454;
  }
`;

export const Content = styled.div`
  margin: 13px 0px;
  display: flex;
  &::after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const OrderCard = styled.div<{ disable?: boolean; increment?: boolean }>`
  &.empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  float: left;
  margin-right: 15px;
  width: 25%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  min-height: 425px;
  padding: 15px;
  height: calc(100vh - 195px);
  position: relative;
  button.bin {
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    color: #ffffff;
    background: #1f5454;
    border: 4px solid #4d9a9a;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    min-height: 55px;
    width: 100%;
    &:hover {
      color: #fff;
      border: 4px solid #4d9a9a;
    }
  }
  div {
    background: #ffffff;
  }
  button {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 4px !important;
    border-radius: 12px;
    background: #1f5454;
    border: 4px solid #4d9a9a;
    min-height: 50px;
    width: 50%;
  }
  button:disabled {
    background: #cccccc;
    border-color: #cccccc !important;
  }
  button.inc-btn,
  button.dec-btn {
    font-size: 30px;
    line-height: 30px;
    :hover {
      border: 4px solid #4d9a9a;
    }
  }
  .count,
  .need,
  .exp {
    font-weight: 700;
  }
  .count {
    color: ${(props) =>
      props.disable === false && props.increment === false
        ? "#444444"
        : props.disable === false && props.increment === true
        ? "#3881E1"
        : "#07A568"};
  }
  .need {
    color: ${(props) => (props.disable === false ? "#1f5454" : "#07A568")};
  }
  .exp {
    color: #f21167;
  }
`;
export const IDContainer = styled.div`
  padding-bottom: 20px;
`;

export const LeftButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  position: absolute;
  bottom: 15px;
  width: 90%;
`;
export const ImgContainer = styled.div`
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12));
  border-radius: 12px;
  height: 120px;
  margin-bottom: 20px;
  text-align: center;
  &.left-img {
    filter: unset;
    border-radius: 0;
    height: auto;
    margin-bottom: 10px;
  }
  &.left {
    svg, img {
      height: 120px;
    }
  }
  svg {
    width: 100%;
    height: auto;
  }
  &.description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const QContainer = styled.div`
  display: flex;
  gap: 25px;
`;

export const Labels = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: rgba(68, 68, 68, 0.6);
  padding-bottom: 5px;
`;

export const ItemName = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #444444;
  &.description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Quantity = styled.div``;

export const SKUId = styled.div``;

export const OrderBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
export const Head = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #444444;
  padding-bottom: 15px;
  span:last-child {
    color: #666666;
  }
`;
export const Text = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #666666;
`;
export const OrderContainer = styled.div``;
export const DropContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;

export const OrderDivider = styled.div`
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  margin: 15px 0px;
`;

export const PickIcon = styled.div<{ color?: string }>`
  svg {
    padding: 0px 7px;
  }
  width: -webkit-fill-available;
  position: relative;
  border: ${(props) =>
    props.color === "completed" || props.color === "hold"
      ? "1px #FFFFFF solid"
      : "1px #888888 solid"};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
  }
`;
export const PickupDisable = styled.div`
  background: #cccccc;
  border-radius: 8px;
  min-width: 235px;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
`;

export const Pickup = styled.div<{ state?: string; okDisable?: boolean }>`
.main {
  display: flex;
  flex-direction: column;
  
 /*  padding-bottom: 7px; */
}
.second {
  /* border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  gap: 7px;
  padding: 7px;
  background: ${(props) =>
    props.state === "completed"
      ? "#07A568"
      : props.state === "hold"
      ? "#F21167"
      : props.state === "progress"
      ? "#E7D84D"
      : props.state === "default"
      ? "#4D9A9A"
      : "#CCCCCC"};
}
  &.no-data {
    min-width: 163px;
    margin-bottom: 15px;
  }
  margin-bottom: 15px;
  min-height: 120px;
  cursor: ${(props) => (props.state === "default" ? "pointer" : "default")};
  background: ${(props) =>
    props.state === "completed"
      ? "#07A568"
      : props.state === "hold"
      ? "#F21167"
      : props.state === "progress"
      ? "#E7D84D"
      : props.state === "default"
      ? "#4D9A9A"
      : "#CCCCCC"};
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => (props.state === "completed" ? "#CCCCCC" : "#ffffff")};
  display: grid;
  grid-template-columns: 70px 1fr;
  padding: 5px;
  gap: 5px;
  display: flex;
  flex-direction: column;
  ${PickIcon} {
    &::before {
      background: ${(props) =>
        props.state === "completed" ? "#377e7e" : "#CCCCCC"};
    }
    button {
      font-weight: 700;
      font-size: 14px;
      width: 75%;
      min-height: 20px;
      border-radius: 4px;
      box-shadow: none !important;
    }
    button.ok-btn {
      pointer-events: ${(props) => (props.okDisable === false ? "" : "none")};
      cursor: ${(props) => (props.okDisable === false ? "pointer" : "default")};
      background: ${(props) =>
        props.okDisable === false ? "#fff" : "transparent"};

      border: 1px solid
        ${(props) => (props.okDisable === false ? "#fff" : "#888")};
      color: ${(props) =>
        props.okDisable === false ? "#07A568 !important" : "#fff !important"};
      :hover {
        border: 1px solid
          ${(props) => (props.okDisable === false ? "#fff" : "#888")};
        color: ${(props) => (props.okDisable === false ? "#07A568" : "#fff")};
      }
    }
  }

  @media only screen and (min-width: 768px) {
  }

  @media only screen and (min-width: 992px) {
    font-size: 12px;
  }
`;

export const Drop = styled.div<{ disable?: boolean }>`
  background: ${(props) => (props.disable === false ? "#4D9A9A" : "#07a568")};
  border-radius: 8px;
  min-width: 235px;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  ${PickIcon} {
    &::before {
      background: ${(props) => (props.disable === false ? "#377E7E" : "#fff")};
    }
  }

  svg {
    fill: ${(props) => (props.disable === false ? "#377E7E" : "#fff")};
  }

  @media only screen and (min-width: 768px) {
    min-width: 95px;
  }

  @media only screen and (min-width: 992px) {
    min-width: 165px;
  }
`;
export const DropDisable = styled.div`
  background: #cccccc;
  border-radius: 8px;
  min-width: 235px;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
`;

export const OrderDetail = styled.div<{
  doneDisabled?: boolean;
}>`
  float: left;
  width: 75%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 195px);
  button {
    font-weight: 700;
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 4px !important;
    border-radius: 12px;
    min-height: 60px;
  }

  button.flag-btn {
    background: #3881e1;
    border: 4px solid #3166ab;
    :hover {
      border: 4px solid #3166ab;
    }
  }
  button.restart-btn {
    background: #ff8b3d;
    border: 4px solid #ff643d;
    :hover {
      border: 4px solid #ff643d;
    }
  }
  button.done-btn {
    pointer-events: ${(props) => (props.doneDisabled === false ? "none" : "")};
    cursor: ${(props) =>
      props.doneDisabled === false ? "default" : "pointer"};
    background: ${(props) =>
      props.doneDisabled === false ? "#cccccc" : "#07a568"};
    border: 4px solid
      ${(props) => (props.doneDisabled === false ? "#cccccc" : "#4d9a9a")};
    :hover {
      border: 4px solid
        ${(props) => (props.doneDisabled === false ? "#cccccc" : "#4d9a9a")};
    }
  }
`;
export const PickImage = styled.div`
  position: relative;
  background: #fff;
  display: flex;
  img,
  svg {
    width: 70px;
    height: 100px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    left: -15px;
    height: 100%;
    border-radius: 10px;
  }
`;
export const Qty = styled.div<{ state?: string }>`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => (props.state === "progress" ? "#444" : "#fff")};
`;

export const PickupContainer = styled.div<{ state?: number,  disable?: boolean }>`
  pointer-events: ${(props) => (props.disable === false ? "" : "none")};
  display: grid;
  grid-template-columns: ${(props) => (`repeat(${props.state}, 1fr)`)};
  column-gap: 15px;
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #333;
  }
`;

export const ScanText = styled.div`
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  color: #666666;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
`;
export const DropIcon = styled.div`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 1px;
    left: -5px;
    height: 100%;
  }
`;
export const DropSlot = styled.div<{ disable?: string }>`
  background: ${(props) =>
    props.disable === "completed"
      ? "#07A568"
      : props.disable === "hold"
      ? "#F21167"
      : props.disable === "progress"
      ? "#E7D84D"
      : props.disable === "default"
      ? "#4D9A9A"
      : "#CCCCCC"};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 5px;
  min-width: 142px;
  height: 30px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #fff;
  svg {
    display: block;
  }
  ${DropIcon} {
    svg {
      height: 15px;
      fill: #fff;
    }
    &::before {
      background: #fff;
    }
  }
`;
