import { Layout } from "antd";
import OrderFulfillment from "../pages/order-fulfillment/OrderFulfillment";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import MaterialInward from "../pages/material-inward/MaterialInward";
import * as Styles from "./styles";
import User from "./icons/user.svg";
import { Button, Dropdown } from "antd";
import { ReactComponent as OrderIcon } from "./icons/order.svg";
import { ReactComponent as MaterialIcon } from "./icons/material.svg";
import { ReactComponent as MaterialPopupIcon } from "./icons/material-popup.svg";
import { ReactComponent as CycleCountIcon } from "./icons/cycle-count.svg";
import PageNotFound from "../components/page-not-found/PageNotFound";
import { format } from "date-fns";
import MaterialOrderDetail from "../pages/material-topup/components/drop-bin-details/DropBinDetails";
import { Navigate } from "react-router-dom";
import MaterialTopup from "../pages/material-topup/MaterialTopup";
import TaskId from "../pages/order-fulfillment/components/task-id/TaskId";
import OrderId from "../pages/order-fulfillment/components/order-id/OrderId";
import SkuBin from "../pages/order-fulfillment/components/order-id/components/sku-bin/SkuBin";
import PickupBin from "../pages/order-fulfillment/components/task-id/components/pickup-bin/PickupBin";
import PickupTote from "../pages/material-inward/components/pickup-tote/PickupTote";
import OrderDetails from "../pages/order-fulfillment/components/task-id/components/order-details/OrderDetails";
import { useState, useEffect, useRef } from "react";
import axios from "../common/api/axios";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../app/app-insights";
import CycleCount from "../pages/cycle-count/CycleCount";

function App() {
  const { Header, Content } = Layout;
  const audioPlayer: any = useRef(null);
  const location = useLocation();
  const [orderCount, setOrderCount] = useState(0);
  const [inwardCount, setInwardCount] = useState(0);
  const [topupCount, setTopupCount] = useState(0);
  const [topupData, setTopupData] = useState([]);
  const [inwardData, setInwardData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const duration: any = process.env.REACT_APP_REFRESH_DURATION;
  const onHandleSignOutClick = () => {
    window.location.replace("https://carteplus-ui-landing-test.azureedge.net/");
  };
  const samData: any = [
    {
      taskType: "Pick",
      id: "d931fe94-c1ba-4761-8e57-6785f5e6c970",
      pickTask: {
        orderReferenceId: "50892004",
        binId: "TOTE01010005",
        quantity: 1,
        skuId: "NFB0002",
        sku: "meal",
        gate: {
          x: 3,
          y: 4,
          z: 2,
        },
      },
      isComplete: false,
      creationDate: "2023-08-28T07:25:55.3207489Z",
    },
    {
      taskType: "Pick",
      id: "913b40a5-7f7a-4865-9aef-e017a4f285ad",
      pickTask: {
        orderReferenceId: "50892005",
        binId: "TOTE01010005",
        quantity: 5,
        skuId: "NFB0003",
        sku: "meal kit-extra_small",
        gate: {
          x: 2,
          y: 4,
          z: 1,
        },
      },
      isComplete: false,
      creationDate: "2023-02-28T07:20:28.709124Z",
    },

    {
      taskType: "Pick",
      id: "d0b1ae24-0409-4bbd-a52a-dac704e4f3d3",
      pickTask: {
        orderReferenceId: "50892004",
        binId: "TOTE01010005",
        quantity: 9,
        skuId: "NEW108",
        sku: "NFB0004",
        gate: {
          x: 1,
          y: 4,
          z: 1,
        },
      },
      isComplete: false,
      creationDate: "2023-07-28T07:04:28.8562335Z",
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      getPickItemsData();
      getPickTopupData();
      getPickInwardData();
    }, duration);
    return () => {
      clearInterval(interval);
    };
  }, []);
  function playAudio() {
    audioPlayer.current.play();
  }
  const getPickItemsData = async () => {
    try {
      //setPickItemLoading(true);
      const res = await axios.get("PickStation/pickItems", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        if (res.data.length !== orderCount) {
          playAudio();
        }
        setOrderCount(res.data.length);
        setOrderData(res.data);
        //setPickItemLoading(false);
      }
    } catch (error: any) { }
  };
  const getPickTopupData = async () => {
    try {
      const res = await axios.get("PickStation/replenishTasks", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        if (res.data.length !== topupCount) {
          playAudio();
        }
        setTopupCount(res.data.length);
        setTopupData(res.data);
      }
    } catch (error: any) { }
  };
  const getPickInwardData = async () => {
    try {
      const res = await axios.get("PickStation/inwardTasks", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        if (res.data.length !== inwardCount) {
          playAudio();
        }
        setInwardCount(res.data.length);
        setInwardData(res.data);
      }
    } catch (error: any) { }
  };

  const current = new Date();
  const date = format(current, "MMM dd, yyyy");

  const time = current.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const items = [
    {
      key: "1",
      label: (
        <div>
          <a className="sign-out" onClick={onHandleSignOutClick}>
            Sign Out
          </a>
        </div>
      ),
    },
  ];
  /*  useEffect(() => {
    const testInterval = setInterval(() => {
      //const out:any = inwardData;
      //const out:any = topupData;
      const out:any = orderData;
      if(samData.length>out.length)
      {
        out.push(samData[out.length])
        setOrderData(out);
        setOrderCount(out.length);
        //setTopupData(out);
        //setTopupCount(out.length);
        //setInwardData(out);
        //setInwardCount(out.length)
      }
    }, 3000)
    return () => {
      clearInterval(testInterval);
    };
  }, []) */

  return (
    <Layout>
      <Header>
        <Styles.Container>
          <Styles.Left>
            <Styles.HeaderLogo />
            <audio
              ref={audioPlayer}
              src={require("./icons/notification-sound.mp3")}
              muted={false}
              autoPlay={true}
            />
            <Styles.NavContainer>
              <Styles.NLink
                to="/order-id"
                className={
                  location?.pathname === "/order_details/task_id" ||
                    location?.pathname === "/task-id" ||
                    location?.pathname === "/order-id" ||
                    location?.pathname === "/pickup_bin/task_id" ||
                    location?.pathname === "/sku_bin/order_id" ||
                    location?.pathname === "/"
                    ? "active"
                    : "none"
                }
              >
                <OrderIcon />
                <Styles.Badge>
                  Order <br /> Fulfillment
                  {orderCount > 0 && <span>{orderCount}</span>}
                </Styles.Badge>
              </Styles.NLink>
              <Styles.NLink
                to="/material-inward"
                className={
                  location?.pathname === "/pickup_tote" ? "active" : "none"
                }
              >
                <MaterialIcon />
                <Styles.Badge>
                  Material <br /> Inwards
                  {inwardCount > 0 && <span>{inwardCount}</span>}
                </Styles.Badge>
              </Styles.NLink>
              <Styles.NLink
                to="/material-topup"
                className={
                  location?.pathname === "/drop_details" ||
                    location?.pathname === "/"
                    ? "active"
                    : "none"
                }
              >
                <MaterialPopupIcon />
                <Styles.Badge>
                  Material <br /> Top-up
                  {topupCount > 0 && <span>{topupCount}</span>}
                </Styles.Badge>
              </Styles.NLink>
              <Styles.NLink
                to="/cycle-count"
                className={
                  location?.pathname === "/drop_details" ||
                    location?.pathname === "/"
                    ? "active"
                    : "none"
                }
              >
                <CycleCountIcon />
                <Styles.Badge>
                  Cycle <br /> Count
                </Styles.Badge>
              </Styles.NLink>
            </Styles.NavContainer>
          </Styles.Left>
          <Styles.Right>
            <Styles.DateContainer>
              <Styles.Time>{time}</Styles.Time>
              <Styles.Date>{date}</Styles.Date>
            </Styles.DateContainer>
            <Dropdown
              className="dropdown"
              menu={{
                items,
              }}
              placement="bottomRight"
              overlayClassName="header-dropdown"
            >
              <Button>
                <Styles.UserImage src={User} />
                <Styles.UserContainer>
                  <Styles.UserName>John Doe</Styles.UserName>
                  <Styles.Role>Operator</Styles.Role>
                </Styles.UserContainer>
              </Button>
            </Dropdown>
          </Styles.Right>
        </Styles.Container>
      </Header>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Routes>
          <Route path="/" element={<OrderFulfillment />}>
            <Route index element={<Navigate to="order-id" />} />
            <Route path="task-id" element={<TaskId />} />
            <Route
              path="order-id"
              element={
                <OrderId taskCount={orderCount} taskDetails={orderData} />
              }
            />
          </Route>
          <Route path="/sku_bin/*" element={<SkuBin />} />
          {/* <Route path="/sku_bin_details/*" element={<OrderDetailNew />} /> */}

          <Route path="/drop_details/*" element={<MaterialOrderDetail />} />

          <Route path="/pickup_bin/*" element={<PickupBin />} />
          <Route path="/order_details/*" element={<OrderDetails />} />
          <Route
            path="/material-inward"
            element={
              <MaterialInward
                taskCount={inwardCount}
                taskDetails={inwardData}
              />
            }
          />
          <Route path="/pickup_tote/*" element={<PickupTote />} />
          <Route
            path="/material-topup"
            element={
              <MaterialTopup taskCount={topupCount} taskDetails={topupData} />
            }
          />
          <Route path="*" element={<OrderFulfillment />} />
          <Route path="/cycle-count" element={<CycleCount />} />
        </Routes>
      </AppInsightsContext.Provider>
      <Content className="site-layout">
        <Outlet />
      </Content>
    </Layout>
  );
}

export default App;
