import styled from "styled-components";

export const Container = styled.div`
  margin: 13px 0px;
  background: #f5f5f5;
`;

export const TaskBar = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  min-height: 60px;
  align-items: center;
  padding: 0px 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Item = styled.div`
  background: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:last-child {
    text-align: right;
  }
  span:first-child {
    color: #444444;
    margin-right: 2px;
  }
  span:last-child {
    color: #1f5454;
  }
`;

export const Content = styled.div`
  margin: 13px 0px;
  display: flex;
  gap: 20px;
`;

export const OrderCard = styled.div`
  min-width: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  min-height: 425px;
  padding: 15px;
  height: calc(100vh - 195px);
  button.bin {
    font-weight: 500;
    font-size: 25px;
    text-align: center;
    color: #ffffff;
    background: #1f5454;
    border: 4px solid #4d9a9a;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    min-height: 55px;
    width: 100%;
    &:hover {
      color: #fff;
      border: 4px solid #4d9a9a;
    }
  }
`;

export const ImgContainer = styled.div`
  svg {
    width: 270px;
    height: 229px;
  }
`;
export const ScanText = styled.div`
  font-weight: 500;
  font-size: 20px;
  text-transform: capitalize;
  color: #666666;
  height: calc(100vh - 515px);
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const Head = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #444444;
  padding-bottom: 15px;
`;
export const Text = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #666666;
`;
export const OrderContainer = styled.div``;

export const OrderDivider = styled.div`
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  margin: 15px 0px;
`;

export const PickIcon = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    left: -15px;
    height: 100%;
  }
`;

export const Pickup = styled.div<{ disable?: boolean }>`
  background: ${(props) => (props.disable === false ? "#CCCCCC" : "#4d9a9a")};
  border-radius: 8px;
  min-width: 235px;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => (props.disable === false ? "#CCCCCC" : "#ffffff")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  svg {
    display: ${(props) => (props.disable === false ? "none" : "block")};
  }
  ${PickIcon} {
    &::before {
      background: ${(props) =>
        props.disable === false ? "#CCCCCC" : "#377e7e"};
    }
  }

  @media only screen and (min-width: 768px) {
    min-width: 95px;
  }

  @media only screen and (min-width: 992px) {
    min-width: 155px;
    font-size: 12px;
  }
`;

export const OrderDetail = styled.div`
  width: 100%;
`;

export const PickupContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;
