import * as Styles from "./styles";
import { Modal } from "antd";

export interface RestartPopupProps {
  visible: boolean;
  isCloseRestartPopup: () => void;
}

export default function RestartPopup({
  visible,
  isCloseRestartPopup,
}: RestartPopupProps) {
  return (
    <Styles.Container>
      <Modal
        wrapClassName="operator"
        open={visible}
        onOk={isCloseRestartPopup}
        onCancel={isCloseRestartPopup}
        closable={false}
        cancelText="Cancel"
        okText="Confirm"
      >
        <Styles.Task selected={true}>
          Restart
          <br />
          The Entire Task
          <Styles.Selected />
        </Styles.Task>
      </Modal>
    </Styles.Container>
  );
}
