import { useEffect, useState } from "react";
import * as Styles from "./styles";
import NewTask from "./components/new-task/NewTask";
import NoTask from "./components/no-task/NoTask";
import { PickItem } from "../../common/models/PickItem";
import axios from "../../common/api/axios";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
export interface TopupProps {
  taskCount: any;
  taskDetails: any;
}
export default function MaterialTopup({taskCount, taskDetails}:TopupProps) {
  const [getPickItems, setPickItems] = useState<any[]>([]);
  const [getPickStations, setPickStations] = useState<any[]>([]);
  const [PickItemLoading, setPickItemLoading] = useState(false);
  const [time, setTime] = useState(Date.now());
  const navigate = useNavigate();
  const sampData = [
    {
        "taskType": "Replenish",
        "id": "140e87a4-3242-4f8b-ba63-58a78e434d1b",
        "replenishTask": {
            "binId": "TOTE01020127",
            "from": {
                "x": 4,
                "y": 4,
                "z": 1
            },
            "skuId": "SAV01",
            "quantity": 10,
            "isApproved": false,
            "binSkus": []
        },
        "isComplete": false,
        "creationDate": "2024-03-07T12:13:20.0583364Z"
    },
    {
        "taskType": "Replenish",
        "id": "2231fbb0-7ad4-4960-956f-5a7f639795c3",
        "replenishTask": {
            "binId": "TOTE01010021",
            "from": {
                "x": 4,
                "y": 4,
                "z": 2
            },
            "skuId": "DET01",
            "quantity": 100,
            "isApproved": false,
            "binSkus": []
        },
        "isComplete": false,
        "creationDate": "2024-03-07T12:12:49.3115806Z"
    }
];
  // GET Pick Items
  const getPickItemsData = async () => {
    try {
      setPickItemLoading(true);
      const res = await axios.get("PickStation/replenishTasks", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setPickItems(res.data);
        //setPickItems(sampData);
        setPickItemLoading(false);
      }
    } catch (error: any) {}
  };
  const getPickStationsData = async () => {
    try {
      setPickItemLoading(true);
      const res = await axios.get("PickStation/pickStations", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setPickStations(res.data);
        setPickItemLoading(false);
      }
    } catch (error: any) {}
  };
  useEffect(() => {
    //getPickItemsData();
    getPickStationsData();
  }, []);
  useEffect(() => {
    setPickItems(taskDetails);
  }, []) 
  
  /* useEffect(() => {
    if (time) {
      const interval = setInterval(() => {
        setTime(Date.now());
        getPickItemsData();
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [time]); */
  const handleChange = (value: string) => {
    if (value === "task-id") {
      navigate("/task-id");
    } else {
      navigate("order-id");
    }
  };
  return (
    <Styles.Container>
      {/* <Styles.SubHead>
        <Select
          bordered={false}
          className="common"
          popupClassName="pick-dropdown"
          defaultValue="order-id"
          onChange={handleChange}
          options={[
            {
              value: "task-id",
              label: "Order Fulfillment Via Task ID",
            },
            {
              value: "order-id",
              label: "Order Fulfillment Via Order ID",
            },
          ]}
        />
      </Styles.SubHead> */}
      {taskDetails?.length === 0 ? (
        <NoTask 
        getPickItemsData={getPickItemsData}
        />
      ) : (
        <NewTask
          PickItemLoading={PickItemLoading}
          pickItemsData={taskDetails}
          taskCount={taskCount}
          pickStationsData={getPickStations}
        />
      )}
    </Styles.Container>
  );
}
