import * as Styles from "./styles";
import { Modal } from "antd";

export interface FlagPopupProps {
  visible: boolean;
  isCloseFlagPopup: () => void;
}

export default function FlagPopup({
  visible,
  isCloseFlagPopup,
}: FlagPopupProps) {
  return (
    <Styles.Container>
      <Modal
        wrapClassName="operator"
        open={visible}
        onOk={isCloseFlagPopup}
        onCancel={isCloseFlagPopup}
        closable={false}
        cancelText="Cancel"
        okText="Confirm"
      >
        <Styles.TaskContainer>
          <Styles.Task selected={false}>
            Insufficient <br />
            quantity in <br />
            tote
            <Styles.Selected />
          </Styles.Task>
          <Styles.Task selected={true}>
            Item <br /> damaged
            <Styles.Selected />
          </Styles.Task>
          <Styles.Task selected={false}>
            Item expired
            <Styles.Selected />
          </Styles.Task>
          <Styles.Task selected={false}>
            QA / QC
            <br />
            Required
            <Styles.Selected />
          </Styles.Task>
        </Styles.TaskContainer>
      </Modal>
    </Styles.Container>
  );
}
