import { useEffect, useState } from "react";
import * as Styles from "./styles";
import axios from "../../../../common/api/axios";
import { useNavigate } from "react-router-dom";
import { Select } from "antd";
import NoOrder from "./components/no-order/NoOrder";
import NewOrder from "./components/new-order/NewOrder";
export interface OrderIdProps {
  taskCount: any;
  taskDetails: any;
}
export default function OrderId({taskCount, taskDetails}: OrderIdProps) {
  const [getPickItems, setPickItems] = useState<any[]>([]);
  const [getPickStations, setPickStations] = useState<any[]>([]);
  const [PickItemLoading, setPickItemLoading] = useState(false);
  const [time, setTime] = useState(Date.now());
  const navigate = useNavigate();
  const sampData = [
    {
      taskType: "Pick",
      id: "d931fe94-c1ba-4761-8e57-6785f5e6c970",
      pickTask: {
        orderReferenceId: "50892004",
        binId: "TOTE01010005",
        quantity: 1,
        skuId: "NFB0002",
        sku: "meal",
        gate: {
          x: 3,
          y: 4,
          z: 2,
        },
      },
      isComplete: false,
      creationDate: "2023-08-28T07:25:55.3207489Z",
    },
    {
      taskType: "Pick",
      id: "913b40a5-7f7a-4865-9aef-e017a4f285ad",
      pickTask: {
        orderReferenceId: "50892005",
        binId: "TOTE01010005",
        quantity: 5,
        skuId: "NFB0003",
        sku: "meal kit-extra_small",
        gate: {
          x: 2,
          y: 4,
          z: 1,
        },
      },
      isComplete: false,
      creationDate: "2023-02-28T07:20:28.709124Z",
    },

    {
      taskType: "Pick",
      id: "d0b1ae24-0409-4bbd-a52a-dac704e4f3d3",
      pickTask: {
        orderReferenceId: "50892004",
        binId: "TOTE01010005",
        quantity: 9,
        skuId: "NEW108",
        sku: "NFB0004",
        gate: {
          x: 1,
          y: 4,
          z: 1,
        },
      },
      isComplete: false,
      creationDate: "2023-07-28T07:04:28.8562335Z",
    },
  ];
  // GET Pick Items
  const getPickItemsData = async () => {
    try {
      setPickItemLoading(true);
      const res = await axios.get("PickStation/pickItems", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        //const outData = res.data;
        setPickItems(res.data);
        //setPickItems(sampData);
        //const outData = sampData;
        /* if(outData.length > 0)
        {
        const uniqueObjs = [...new Set(outData.map((obj: any) => obj.pickTask.orderReferenceId))]
  .map(id => outData.find((obj: any) => obj.pickTask.orderReferenceId === id)); // Remove duplicate order id's
        setPickItems(uniqueObjs);
        }
        else{
          setPickItems(outData);
        } */
        setPickItemLoading(false);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    //getPickItemsData();
    getPickStationsData();
  }, []);
  const getPickStationsData = async () => {
    try {
      setPickItemLoading(true);
      const res = await axios.get("PickStation/pickStations", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setPickStations(res.data);
        //setPickItems(sampData);
        setPickItemLoading(false);
      }
    } catch (error: any) {}
  };
  /* useEffect(() => {
    if (time) {
      const interval = setInterval(() => {
        setTime(Date.now());
        getPickItemsData();
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [time]); */
  const handleChange = (value: string) => {
    if (value === "task-id") {
      navigate("/task-id");
    } else {
      navigate("order-id");
    }
  };
  /* useEffect(() => {
    console.log('out--')
    setPickItems(taskDetails);
  },[])  */
  return (
    <Styles.Container>
      {/* <Styles.SubHead>
        <Select
          bordered={false}
          className="common"
          popupClassName="pick-dropdown"
          defaultValue="order-id"
          onChange={handleChange}
          options={[
            {
              value: "task-id",
              label: "Order Fulfillment Via Task ID",
            },
            {
              value: "order-id",
              label: "Order Fulfillment Via Order ID",
            },
          ]}
        />
      </Styles.SubHead> */}
      {taskDetails?.length === 0 ? (
        <NoOrder
        getPickItemsData={getPickItemsData}
        />
      ) : (
        <NewOrder
          PickItemLoading={PickItemLoading}
          pickItemsData={taskDetails}
          taskCount={taskCount}
          pickStationsData={getPickStations}
        />
      )}
    </Styles.Container>
  );
}
