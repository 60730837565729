import styled from "styled-components";

export const Container = styled.div``;

export const SubHead = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-top: 15px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
