import { useEffect, useState } from "react";
import * as Styles from "./styles";
import axios from "../../common/api/axios";
import { useNavigate } from "react-router-dom";
import NoTask from "./components/no-task/NoTask";
import NewTask from "./components/new-task/NewTask";
export interface InwardProps {
  taskCount: any;
  taskDetails: any;
}
export default function MaterialInward({taskCount, taskDetails}:InwardProps) {
  const samData = [
    {
      taskType: "Inward",
      id: "79e25f6b-1c34-4ac2-83e7-a34c2ef3911d",
      inwardTask: {
        from: {
          x: 8,
          y: 4,
          z: 1,
        },
        skuId: "NEW108",
        quantity: 2,
        purpose: "ItemPutAway",
      },
      isComplete: false,
      creationDate: "2023-08-22T14:06:13.6875484Z",
    },
    {
      taskType: "Inward",
      id: "64a01c5c-0b75-4b36-ae68-4ead68c4f070",
      inwardTask: {
        from: {
          x: 9,
          y: 4,
          z: 1,
        },
        skuId: "NFB0003",
        quantity: 1,
        purpose: "ItemPutAway",
      },
      isComplete: false,
      creationDate: "2023-08-22T14:06:03.4555424Z",
    },
    {
      taskType: "Inward",
      id: "64a01c5c-0b75-4b36-ae68-4ead68c4f070",
      inwardTask: {
        from: {
          x: 11,
          y: 4,
          z: 1,
        },
        skuId: "NFB0003",
        quantity: 1,
        purpose: "ItemPutAway",
      },
      isComplete: false,
      creationDate: "2023-08-22T14:06:03.4555424Z",
    },
  ];
  const [getPickItems, setPickItems] = useState<any[]>([]);
  const [getPickStations, setPickStations] = useState<any[]>([]);
  const [PickItemLoading, setPickItemLoading] = useState(false);
  const [time, setTime] = useState(Date.now());
  const navigate = useNavigate();
  // GET Pick Items
  const getPickItemsData = async () => {
    try {
      setPickItemLoading(true);
      const res = await axios.get("PickStation/inwardTasks", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setPickItems(res.data);
        //setPickItems(samData);
        setPickItemLoading(false);
      }
    } catch (error: any) {}
  };

 const getPickStationsData = async () => {
    try {
      setPickItemLoading(true);
      const res = await axios.get("PickStation/inwardStations", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setPickStations(res.data);
        //setPickItems(samData);
        setPickItemLoading(false);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
   // getPickItemsData();
    getPickStationsData();
  }, []);
   useEffect(() => {
    setPickItems(taskDetails);
  }, []) 

  /* useEffect(() => {
    if (time) {
      const interval = setInterval(() => {
        setTime(Date.now());
        getPickItemsData();
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [time]); */

  return (
    <Styles.Container>
      {taskDetails?.length === 0 ? (
        <NoTask 
        getPickItemsData={getPickItemsData}
        />
      ) : (
        <NewTask
          PickItemLoading={PickItemLoading}
          pickItemsData={taskDetails}
          taskCount={taskCount}
          pickStationsData={getPickStations}
        />
      )}
    </Styles.Container>
  );
}
