import * as Styles from "./styles";
import { ReactComponent as NewTaskIcon } from "./icons/new-task.svg";
import { ReactComponent as ScanIcon } from "./icons/scan.svg";
import { Button, Input, message, Select } from "antd";
import type { RefSelectProps } from "antd";
import { PickItem } from "../../../../../../common/models/PickItem";
import { formatDistance } from "date-fns";
import { useEffect, useState, useRef } from "react";
import { ReactComponent as CardLoaderIcon } from "../../../../../../assets/icons/card_loader.svg";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Toast } from "./icons/info.svg";
import SkuBin from "../sku-bin/SkuBin";

const barcodeURL: any = process.env.REACT_APP_BARCODE_URL;
const barcodeFormat: any = process.env.REACT_APP_BARCODE_FORMAT;

export interface NewOrderProps {
  pickItemsData: any[];
  PickItemLoading: boolean;
  taskCount: any;
  pickStationsData: any[];
}

export default function NewOrder({
  pickItemsData,
  PickItemLoading,
  taskCount,
  pickStationsData
}: NewOrderProps) {
  const RefSelectProps = useRef<RefSelectProps>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [startLoading, setStartLoading] = useState(false);
  const [stationFirstData, setStationFirstData] = useState([]);
  const [stationSecondData, setStationSecondData] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [orderCodeUri, setOrderCodeUri] = useState("");
  const [dropValue, setDropValues] = useState([]);
  const [clickStatus, setClickStatus] = useState(false);
  const [clickData, setClickData] = useState(false);
  const hideShimmer = pickItemsData?.length === 1;
  //const taskCount = pickItemsData?.length;
  const navigate = useNavigate();
  useEffect(() => {
    getStationsData();
  }, [pickStationsData]);
  const getStationsData = () => {
    let first_out:any = [];
    let second_out:any = [];
    if(pickStationsData.length > 0)
    {
    pickStationsData[0].gates.map((val:any) => {
      if(val.slot["z"] == 2)
      {
        const out_str = `${val.slot["x"]},${val.slot["y"]},${val.slot["z"]}`;
        first_out.push(out_str);
      }
      if(val.slot["z"] == 1)
      {
        const out_str = `${val.slot["x"]},${val.slot["y"]},${val.slot["z"]}`;
        second_out.push(out_str);
      }
      
    })
    first_out.sort((a:any,b:any) => {
    return b[0]-a[0]
    });
    second_out.sort((a:any,b:any) => {
      return b[0]-a[0]
      });
    setStationFirstData(first_out);
    setStationSecondData(second_out);
    }
  };
  useEffect(() => {
    if (pickItemsData.length > 0) {
      setOrderCodeUri(pickItemsData[0].pickTask.orderReferenceId);
      const dropValues: any = pickItemsData.map((val: any, Index: any) => {
        return {
          ...val,
          value: val.pickTask.orderReferenceId,
        };
      });
      const uniqueObjs:any = [...new Set(dropValues.map((obj: any) => obj.pickTask.orderReferenceId))]
  .map(id => dropValues.find((obj: any) => obj.pickTask.orderReferenceId === id))
      setDropValues(uniqueObjs);
    }
    setTimeout(() => {
      RefSelectProps.current !== null && RefSelectProps.current!.focus();
    }, 500);
  }, [pickItemsData]);
  const clickStart = (clickedItem: any) => {
    // alert(clickedItem)
    if (clickedItem !== "") {
      
       const isFound = pickItemsData.some((element) => {
        if (element.pickTask.orderReferenceId === clickedItem) {
          setStartLoading(true);
          const filteredArray:any = pickItemsData.filter(
            (item: any) => item.pickTask.orderReferenceId === clickedItem
          );
          setClickStatus(true);
          setClickData(filteredArray);
          /* setTimeout(() => {
            setStartLoading(false);
            navigate(`/sku_bin/order_id?:${clickedItem}`, {
              state: {
                  filteredArray:  filteredArray,
                  pickStationsFirstData: stationFirstData,
                  pickStationsSecondData: stationSecondData,
                }
            });
          }, 1000); */
        } else {
          setTimeout(() => {
            messageApi.open({
              type: "error",
              content: "Entered the scan ID does not exist !",
              icon: <Toast />,
              className: "error",
              style: {
                marginTop: "85vh",
              },
            });
          }, 1100);
        }
      }); 
    }
  };

  const sortedPickItem = pickItemsData?.sort((a, b) => {
    if (a.creationDate < b.creationDate) {
      return 1;
    }
    if (a.creationDate > b.creationDate) {
      return -1;
    }
    return 0;
  });

  const dateTimeFormat = (time: string) => {
    const distance = formatDistance(Date.now(), new Date(time), {
      addSuffix: true,
    });
    const firstWord = distance.replace(/in/g, "");
    const finalWord = firstWord.replace(/about/g, "");
    const formatted = finalWord.replace(/mutes/g, "Mins");
    const one = formatted.replace(/mute/g, "Minute");
    const two = one.replace(/ago/g, "");
    return two;
  };

  const onChange = (value: string) => {
    setOrderId(value);

    //console.log(`selected ${value}`);
  };

  const onSearch = (value: string) => {
    console.log("search:", value);
  };

  // Filter `option.label` match the user type `input`
  const filterOption = (
    input: string,
    option: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    clickStatus ? 
      <SkuBin pickItemsData={pickItemsData} pickStationsFirstData={stationFirstData} pickStationsSecondData={stationSecondData} />
    :
    <Styles.Container hide={hideShimmer}>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>

      <Styles.Parent>
        {sortedPickItem?.map((item: any, index) => {
          return (
            <Styles.Child key={index} PickItemLoading={PickItemLoading}>
              <Styles.ChildLoad>
                <CardLoaderIcon />
              </Styles.ChildLoad>
              <Styles.Header>
                {/*  <NewTaskIcon /> */}
                <Styles.HeaderText>New Pick-task Available !</Styles.HeaderText>
              </Styles.Header>
              <Styles.Content className="enter">
                <Styles.Item>
                  {orderCodeUri !== "" ? (
                    <img
                      src={barcodeURL + orderCodeUri + '/150/300/' + barcodeFormat}
                      alt="product image"
                      width={"100%"}
                    />
                  ) : (
                    <ScanIcon />
                  )}
                </Styles.Item>
                {/* <Styles.Item>
                  <span>Scan Order ID To Start</span>
                </Styles.Item> */}
                <Styles.Item>
                  {/*  <Input
                    className="tote-input"
                    name="pickup-tote"
                    value={orderId}
                    
                    onChange={(e) => {
                      setOrderId(e.target.value);
                    }}
                    onPressEnter={(event) => {
                      clickStart(event);
                    }}
                    
                  /> */}
                  <span>Scan / Choose Order ID To Start</span>
                </Styles.Item>
                {/* <Input
                  name="orderId"
                  onChange={(e) => {
                    setOrderId(e.target.value);
                  }}
                /> */}
                <Select
                  showSearch
                  placeholder="Select Order ID"
                  optionFilterProp="children"
                  onChange={clickStart}
                  ref={RefSelectProps}
                  //onSelect={clickStart}
                  //onSearch={onSearch}
                  //filterOption={filterOption}
                  filterOption={(inputValue, option: any) =>
                    option.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  options={dropValue}
                />
              </Styles.Content>
              {/* <Styles.Footer disable={orderId === ""}>
                <Button onClick={() => clickStart(item)} loading={startLoading}>
                  {startLoading === true ? "Loading..." : "SCAN"}
                </Button>
              </Styles.Footer> */}
              <Styles.Footer>
                <Styles.SubMessage>
                  -{" "}
                  {taskCount !== undefined && taskCount < 10
                    ? ("0" + `${taskCount}`).slice(-2)
                    : taskCount}{" "}
                  orders are in queue -
                </Styles.SubMessage>
              </Styles.Footer>
            </Styles.Child>
          );
        })}
        {/* <Styles.SubMessage>
          -{" "}
          {taskCount !== undefined && taskCount < 10
            ? ("0" + `${taskCount}`).slice(-2)
            : taskCount}{" "}
          orders are in queue -
        </Styles.SubMessage> */}
      </Styles.Parent>

      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <>{contextHolder}</>
    </Styles.Container>
  );
}
