import styled from "styled-components";

export const Parent = styled.div`
  width: 25%;
  margin: 10px 0px;
  position: relative;
  height: 420px;
`;

export const ChildLoad = styled.div`
  width: 100%;
  position: absolute;
  height: 435px;
  background: #ffffff;
  border-radius: 12px;
  align-items: center;
  z-index: 2;
  padding-top: 30%;
  :first-child {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  }
`;

export const Child = styled.div<{ PickItemLoading: boolean }>`
  width: 100%;
  position: absolute;
  height: 435px;
  background: #ffffff;
  border-radius: 12px;
  :first-child {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  }
  ${ChildLoad} {
    display: ${(props) => (props.PickItemLoading === true ? "block" : "none")};
  }
`;

export const SubMessage = styled.div`
  text-align: center;
  position: absolute;
  top: 450px;
  font-weight: 500;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Shimmer = styled.div`
  width: 25%;
  height: 300px;
  background: #e6e6e6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  &:first-child,
  &:last-child {
    width: 10%;
  }
`;

export const HeaderText = styled.div`
  font-weight: 700;
  font-size: 20px;
  background: #fff;
  text-align: center;
  padding-top: 10px;
  color: #1f5454;
`;
export const Header = styled.div`
  padding: 20px;
  text-align: center;
  background: #fff;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const Item = styled.div`
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 20px;
  background: #fff;
  span:first-child {
    color: #444444;
  }
  span:last-child {
    color: #1f5454;
    padding-left: 5px;
    text-transform: capitalize;
    word-break: break-all;
  }
`;

export const Content = styled.div`
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  padding: 30px;
  background: #fff;
  text-align: center;
  ${Item}:last-child {
    padding-bottom: 0px;
  }
`;

export const Footer = styled.div`
  padding: 20px;
  background: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  button {
    width: 100%;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    color: #ffffff;
    background: #07a568;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    min-height: 50px;
    &:hover {
      border-color: #07a568 !important;
      color: #ffffff !important;
    }
  }
`;

export const Container = styled.div<{ hide?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 95px);
  gap: 15px;
  margin-left: -15px;
  margin-right: -15px;
  ${Shimmer},${SubMessage} {
    visibility: ${(props) => (props.hide === true ? "hidden" : "visible")};
  }
`;
