import * as Styles from "./styles";
import { ReactComponent as NewTaskIcon } from "./icons/new-task.svg";
import { Button } from "antd";
import { PickItem } from "../../../../common/models/PickItem";
import { formatDistance } from "date-fns";
import { useState, useEffect } from "react";
import { ReactComponent as CardLoaderIcon } from "../../../../assets/icons/card_loader.svg";
import { useNavigate } from "react-router-dom";
import DropBinDetails from "../drop-bin-details/DropBinDetails";

export interface NewTaskProps {
  pickItemsData: PickItem[];
  PickItemLoading: boolean;
  taskCount: any;
  pickStationsData: any;
}

export default function NewTask({
  pickItemsData,
  PickItemLoading,
  taskCount,
  pickStationsData
}: NewTaskProps) {
  const [startLoading, setStartLoading] = useState(false);
  const [stationData, setStationData] = useState([]);
  const [stationFirstData, setStationFirstData] = useState([]);
  const [stationSecondData, setStationSecondData] = useState([]);
  const [clickStatus, setClickStatus] = useState(false);
  const [clickData, setClickData] = useState(false);
  const hideShimmer = pickItemsData?.length === 1;
  //const taskCount = pickItemsData?.length;
  const navigate = useNavigate();
 /*  useEffect(() => {
    getStationsData();
  }, [pickStationsData]);
  const getStationsData = () => {
    let out:any = [];
    if(pickStationsData.length > 0)
    {
    pickStationsData[0].gates.map((val:any) => {
      const out_str = `${val.slot["x"]},${val.slot["y"]},${val.slot["z"]}`;
      out.push(out_str);
    })
    // out.sort((a:any,b:any) => {
    // return b[0]-a[0]
    // }); 
    setStationData(out.reverse());
    }
  }; */
  useEffect(() => {
    getStationsData();
  }, [pickStationsData]);
  const getStationsData = () => {
    let first_out:any = [];
    let second_out:any = [];
    if(pickStationsData.length > 0)
    {
    pickStationsData[0].gates.map((val:any) => {
      if(val.slot["z"] == 2)
      {
        const out_str = `${val.slot["x"]},${val.slot["y"]},${val.slot["z"]}`;
        first_out.push(out_str);
      }
      if(val.slot["z"] == 1)
      {
        const out_str = `${val.slot["x"]},${val.slot["y"]},${val.slot["z"]}`;
        second_out.push(out_str);
      }
      
    })
    first_out.sort((a:any,b:any) => {
    return b[0]-a[0]
    });
    second_out.sort((a:any,b:any) => {
      return b[0]-a[0]
      });
    setStationFirstData(first_out);
    setStationSecondData(second_out);
    }
  };
  const clickStart = (clickedItem: any) => {
    setStartLoading(true);
    setClickStatus(true);
    setClickData(clickedItem);
   /*  setTimeout(() => {
      setStartLoading(false);
      navigate(`/drop_details`, {
        state: {
          clickedItem:  clickedItem,
          pickStationsFirstData: stationFirstData,
          pickStationsSecondData: stationSecondData,
          }
      });
    }, 1000); */
  };

  const sortedPickItem = pickItemsData?.sort((a, b) => {
    if (a.creationDate < b.creationDate) {
      return 1;
    }
    if (a.creationDate > b.creationDate) {
      return -1;
    }
    return 0;
  });

  const dateTimeFormat = (time: string) => {
    const distance = formatDistance(Date.now(), new Date(time), {
      addSuffix: true,
    });
    const firstWord = distance.replace(/in/g, "");
    const finalWord = firstWord.replace(/about/g, "");
    const formatted = finalWord.replace(/mutes/g, "Mins");
    const one = formatted.replace(/mute/g, "Minute");
    const two = one.replace(/ago/g, "");
    return two;
  };

  return (
    clickStatus ? 
    <DropBinDetails pickItemsData={pickItemsData} pickStationsFirstData={stationFirstData} pickStationsSecondData={stationSecondData} />
  :
    <Styles.Container hide={hideShimmer}>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>

      <Styles.Parent>
        {sortedPickItem?.map((item: any, index) => {
          return (
            <Styles.Child key={index} PickItemLoading={PickItemLoading}>
              <Styles.ChildLoad>
                <CardLoaderIcon />
              </Styles.ChildLoad>
              <Styles.Header>
                <NewTaskIcon />
                <Styles.HeaderText>New Task Available !</Styles.HeaderText>
              </Styles.Header>
              <Styles.Content>
                <Styles.Item>
                  <span>Task ID:</span>
                  <span>{item?.id}</span>
                </Styles.Item>
                <Styles.Item>
                  <span>Items:</span>
                  <span>
                    {" "}
                    {item?.replenishTask?.quantity < 10
                      ? ("0" + `${item?.replenishTask?.quantity}`).slice(-2)
                      : item?.replenishTask?.quantity}{" "}
                  </span>
                </Styles.Item>
                <Styles.Item>
                  <span>Placed @</span>
                  <span>{dateTimeFormat(item?.creationDate)} Ago</span>
                </Styles.Item>
              </Styles.Content>
              <Styles.Footer>
                <Button
                  onClick={() => clickStart(sortedPickItem)}
                  loading={startLoading}
                >
                  {startLoading === true ? "Loading..." : "START"}
                </Button>
              </Styles.Footer>
            </Styles.Child>
          );
        })}
        <Styles.SubMessage>
          -{" "}
          {taskCount !== undefined && taskCount < 10
            ? ("0" + `${taskCount}`).slice(-2)
            : taskCount}{" "}
          tasks are in queue -
        </Styles.SubMessage>
      </Styles.Parent>

      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
    </Styles.Container>
  );
}
