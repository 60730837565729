import * as Styles from "./styles";
import { useEffect } from "react";
import { ReactComponent as NoTaskIcon } from "./icons/no-task.svg";
const duration:any = process.env.REACT_APP_REFRESH_DURATION;
export interface NoTaskProps {
  getPickItemsData: () => void;
}
export default function NoTask({
  getPickItemsData,
  
}: NoTaskProps) {
  useEffect(() => {
    const interval = setInterval(() => {
     getPickItemsData();
    }, duration);
    return () => {
      clearInterval(interval);
    };
}, []);
  return (
    <Styles.Container>
      <NoTaskIcon />
      <Styles.NoTaskText>
        No Pick-task Available, Please Wait.
      </Styles.NoTaskText>
    </Styles.Container>
  );
}
