import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Image } from 'antd';
import { ColumnsType } from 'antd/es/table';
import axios from "../../common/api/axios";

interface Bin {
    binId: string;
    skus: BinSKU[];
    slotAssigned: boolean;
    isLocked: boolean;
    isDecommissioned: boolean;
    lockedBy: string;
    partitionCount: number;
    isFlagged: boolean;
    isStatic: boolean;
    remarks: string;
}

interface BinSKU {
    skuId: string;
    quantity: number;
    partition: string;
    skuBarCode: string;
}

interface SKUDetails {
    imageUri: string;
    description: string;
    isFlagged: boolean;
}

interface SKU extends BinSKU {
    binId: string;
    imageUri: string;
    description: string;
    isFlagged: boolean;
}

interface CycleCountData {
    id: string;
    issueType: number;
    status: number;
    completionTime: string;
    toteIds: string[];
    skuId: string;
    quantityAdjusted: number;
    remarks: string;
}

const CycleCount: React.FC = () => {
    const [skus, setSkus] = useState<SKU[]>([]);
    const [selectedSku, setSelectedSku] = useState<SKU | null>(null);
    const [isCycleCounting, setIsCycleCounting] = useState(false);
    const [quantityChange, setQuantityChange] = useState(0);
    const [cycleCountId, setCycleCountId] = useState<string | null>(null);

    useEffect(() => {
        const fetchSkusFromBins = async () => {
            const response = await axios.get<Bin[]>('/BinMaster/bins');
            const skusFromBins: SKU[] = [];

            for (const bin of response.data) {
                if (bin.isFlagged) {
                    for (const sku of bin.skus) {
                        const skuDetails = await axios.get<SKUDetails>(`/MasterData/sku/${sku.skuId}`);
                        if (skuDetails.data.isFlagged) {
                            skusFromBins.push({
                                ...sku,
                                binId: bin.binId,
                                imageUri: skuDetails.data.imageUri,
                                description: skuDetails.data.description,
                                isFlagged: skuDetails.data.isFlagged,
                            });
                        }
                    }
                }
            }

            setSkus(skusFromBins);

            if (selectedSku && !skusFromBins.some(sku => sku.skuId === selectedSku.skuId)) {
                setSelectedSku(null);
                if (isCycleCounting) {
                    setIsCycleCounting(false);
                    setQuantityChange(0);
                    setCycleCountId(null);
                }
            }
        };

        fetchSkusFromBins();
        const interval = setInterval(fetchSkusFromBins, 5000);
        return () => clearInterval(interval);
    }, []);

    const columns: ColumnsType<SKU> = [
        {
            title: 'SKU ID',
            dataIndex: 'skuId',
            key: 'skuId',
            render: (text: string, record: SKU) => (
                <a onClick={() => setSelectedSku(record)}>{text}</a>
            ),
        },
        {
            title: 'Bin ID',
            dataIndex: 'binId',
            key: 'binId',
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Image',
            dataIndex: 'imageUri',
            key: 'imageUri',
            render: (imageUri: string) => (
                <Image src={imageUri} alt="SKU" width={50} />
            ),
        },
        {
            title: 'Partition',
            dataIndex: 'partition',
            key: 'partition',
        },
        {
            title: 'SKU Barcode',
            dataIndex: 'skuBarCode',
            key: 'skuBarCode',
        },
    ];

    const startCycleCounting = async (): Promise<void> => {
        if (selectedSku) {
            try {
                const response = await axios.post<CycleCountData>(`/CycleCount/create/${selectedSku.skuId}/2`);
                setCycleCountId(response.data.id);
                setIsCycleCounting(true);
            } catch (error) {
                console.error("Error starting cycle count:", error);
            }
        }
    };

    const stopCycleCounting = async (): Promise<void> => {
        if (selectedSku && cycleCountId) {
            try {
                await Modal.confirm({
                    title: 'Confirm Stop Cycle Counting',
                    content: 'Are you sure you want to stop cycle counting?',
                    onOk: async () => {
                        try {
                            const totalQuantity = selectedSku.quantity + quantityChange;
                            if (quantityChange > 0) {
                                await axios.post(`/BinMaster/items/${selectedSku.binId}/${selectedSku.skuId}/${quantityChange}`);
                            } else if (quantityChange < 0) {
                                await axios.post(`/BinMaster/items/remove/${selectedSku.binId}/${selectedSku.skuId}/${Math.abs(quantityChange)}`);
                            }

                            const cycleCountData: CycleCountData = {
                                id: cycleCountId,
                                issueType: 2,
                                status: 0,
                                completionTime: new Date().toISOString(),
                                toteIds: [selectedSku.binId],
                                skuId: selectedSku.skuId,
                                quantityAdjusted: quantityChange,
                                remarks: 'string',
                            };

                            await axios.put(`/BinMaster/flag/${selectedSku.binId}/false/remarks`);
                            await axios.put('/CycleCount/complete', cycleCountData);
                            setIsCycleCounting(false);
                            setQuantityChange(0);
                            setCycleCountId(null);

                            setSelectedSku(prevSku => {
                                if (prevSku) {
                                    return {
                                        ...prevSku,
                                        quantity: totalQuantity,
                                        isFlagged: false
                                    };
                                }
                                return null;
                            });

                            setSkus(prevSkus => prevSkus.map(sku =>
                                sku.skuId === selectedSku.skuId
                                    ? { ...sku, quantity: totalQuantity, isFlagged: false }
                                    : sku
                            ));

                            Modal.success({
                                title: 'Success',
                                content: 'Cycle count completed successfully'
                            });
                        } catch (error) {
                            Modal.error({
                                title: 'Error',
                                content: 'Failed to complete cycle count'
                            });
                        }
                    }
                });
            } catch (error) {
                console.error("Error stopping cycle count:", error);
            }
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px' }}>
            <Table
                dataSource={skus}
                columns={columns}
                onRow={(record) => ({
                    onClick: () => setSelectedSku(record),
                })}
            />
            {selectedSku && (
                <div style={{
                    width: '300px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    padding: '16px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
                }}>
                    {/* Detail view */}
                    <Image src={selectedSku.imageUri} alt={selectedSku.description} />
                    <p>SKU ID: {selectedSku.skuId}</p>
                    <p>Description: {selectedSku.description}</p>
                    <p>Quantity: {selectedSku.quantity}</p>
                    <Button onClick={startCycleCounting} disabled={isCycleCounting} type="primary">
                        Start Cycle Counting
                    </Button>
                    {isCycleCounting && (
                        <>
                            <p style={{
                                color: '#1890ff',
                                fontWeight: 'bold',
                                margin: '16px 0',
                                padding: '8px',
                                backgroundColor: '#e6f7ff',
                                border: '1px solid #91d5ff',
                                borderRadius: '4px'
                            }}>
                                Please wait for the tote to arrive at the pick gate
                            </p>
                            <Button
                                onClick={() => setQuantityChange(quantityChange + 1)}
                                style={{
                                    marginTop: '8px',
                                    marginRight: '8px',
                                    backgroundColor: '#8096A6',
                                    borderColor: '#8096A6',
                                    color: 'white'
                                }}
                            >
                                Increase Quantity +
                            </Button>
                            <Button
                                onClick={() => setQuantityChange(quantityChange - 1)}
                                style={{
                                    marginTop: '8px',
                                    backgroundColor: '#8096A6',
                                    borderColor: '#8096A6',
                                    color: 'white'
                                }}
                            >
                                Decrease Quantity -
                            </Button>
                            <p>Quantity Changed: {quantityChange}</p>
                            <Button onClick={stopCycleCounting} type="primary">Stop Cycle Counting</Button>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CycleCount;
