import * as Styles from "./styles";
import { ReactComponent as NewTaskIcon } from "./icons/new-task.svg";
import { Button } from "antd";
import { PickItem } from "../../../../../../common/models/PickItem";
import { formatDistance } from "date-fns";
import { useState } from "react";
import { ReactComponent as CardLoaderIcon } from "../../../../../../assets/icons/card_loader.svg";
import { useNavigate } from "react-router-dom";

export interface NewTaskProps {
  pickItemsData: PickItem[];
  PickItemLoading: boolean;
}

export default function NewTask({
  pickItemsData,
  PickItemLoading,
}: NewTaskProps) {
  const [startLoading, setStartLoading] = useState(false);
  const hideShimmer = pickItemsData?.length === 1;
  const taskCount = pickItemsData?.length;
  const navigate = useNavigate();

  const clickStart = (clickedItem: any) => {
    setStartLoading(true);
    setTimeout(() => {
      setStartLoading(false);
      navigate(
        `/pickup_bin/task_id?:${clickedItem?.pickTask.binId}_${clickedItem?.pickTask.skuId}_${clickedItem?.pickTask.quantity}`,
        {
          state: clickedItem,
        }
      );
    }, 1000);
  };

  const sortedPickItem = pickItemsData?.sort((a, b) => {
    if (a.creationDate < b.creationDate) {
      return 1;
    }
    if (a.creationDate > b.creationDate) {
      return -1;
    }
    return 0;
  });

  const dateTimeFormat = (time: string) => {
    const distance = formatDistance(Date.now(), new Date(time), {
      addSuffix: true,
    });
    const firstWord = distance.replace(/in/g, "");
    const finalWord = firstWord.replace(/about/g, "");
    const formatted = finalWord.replace(/mutes/g, "Mins");
    const one = formatted.replace(/mute/g, "Minute");
    const two = one.replace(/ago/g, "");
    return two;
  };

  return (
    <Styles.Container hide={hideShimmer}>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>

      <Styles.Parent>
        {sortedPickItem?.map((item: any, index) => {
          return (
            <Styles.Child key={index} PickItemLoading={PickItemLoading}>
              <Styles.ChildLoad>
                <CardLoaderIcon />
              </Styles.ChildLoad>
              <Styles.Header>
                <NewTaskIcon />
                <Styles.HeaderText>New Pick-task Available !</Styles.HeaderText>
              </Styles.Header>
              <Styles.Content>
                <Styles.Item>
                  <span>Task ID:</span>
                  <span>
                    {item?.pickTask.binId}_{item?.pickTask.skuId}_
                    {item?.pickTask.quantity}
                  </span>
                </Styles.Item>
                <Styles.Item>
                  <span>Order ID:</span>
                  <span>{item?.pickTask.orderReferenceId}</span>
                </Styles.Item>
                <Styles.Item>
                  <span>Placed @</span>
                  <span>{dateTimeFormat(item?.creationDate)} Ago</span>
                </Styles.Item>
              </Styles.Content>
              <Styles.Footer>
                <Button onClick={() => clickStart(item)} loading={startLoading}>
                  {startLoading === true ? "Loading..." : "START"}
                </Button>
              </Styles.Footer>
            </Styles.Child>
          );
        })}
        <Styles.SubMessage>
          -{" "}
          {taskCount !== undefined && taskCount < 10
            ? ("0" + `${taskCount}`).slice(-2)
            : taskCount}{" "}
          tasks are in queue -
        </Styles.SubMessage>
      </Styles.Parent>

      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
      <Styles.Shimmer className="shimmer"></Styles.Shimmer>
    </Styles.Container>
  );
}
