import * as Styles from "./styles";
import { ReactComponent as PickupIcon } from "./icons/pickup.svg";
import { ReactComponent as ScanIcon } from "./icons/scan.svg";
import { Button } from "antd";
import { formatDistance } from "date-fns";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface PickupBinProps {}

export default function PickupBin({}: PickupBinProps) {
  const [binLoading, setBinLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const startedItem = location?.state;

  const onBinScanned = (locatedItem: any) => {
    setBinLoading(true);
    setTimeout(() => {
      setBinLoading(false);
      navigate(
        `/order_details/task_id?:${locatedItem?.pickTask.binId}_${locatedItem?.pickTask.skuId}_${locatedItem?.pickTask.quantity}`,
        {
          state: locatedItem,
        }
      );
    }, 1000);
  };

  const dateTimeFormat = (time: string) => {
    const distance = formatDistance(Date.now(), new Date(time), {
      addSuffix: true,
    });
    const firstWord = distance.replace(/in/g, "");
    const finalWord = firstWord.replace(/about/g, "");
    const formatted = finalWord.replace(/mutes/g, "Mins");
    const one = formatted.replace(/mute/g, "Minute");
    const two = one.replace(/ago/g, "");
    return two;
  };

  return (
    <Styles.Container className="order-detail">
      <>
        <Styles.TaskBar>
          <Styles.Item>
            <span>Task ID:</span>
            <span>
              {startedItem?.pickTask.binId}_{startedItem?.pickTask.skuId}_
              {startedItem?.pickTask.quantity}
            </span>
          </Styles.Item>
          <Styles.Item>
            <span>Order ID:</span>
            <span>{startedItem?.pickTask.orderReferenceId}</span>
          </Styles.Item>
          <Styles.Item>
            <span>Placed @</span>
            {startedItem?.creationDate !== undefined && (
              <span>{dateTimeFormat(startedItem?.creationDate)} Ago</span>
            )}
          </Styles.Item>
        </Styles.TaskBar>
        <Styles.Content>
          <Styles.OrderCard>
            <Styles.ImgContainer>
              <ScanIcon />
            </Styles.ImgContainer>
            <Styles.ScanText>
              Scan the assigned
              <br /> bin’s barcode
            </Styles.ScanText>
            {startedItem !== undefined && (
              <Button
                className="bin"
                onClick={() => onBinScanned(startedItem)}
                loading={binLoading}
              >
                {binLoading === true ? "Loading..." : "Bin Located"}
              </Button>
            )}
          </Styles.OrderCard>
          <Styles.OrderDetail>
            <Styles.OrderContainer>
              <Styles.Head>Pickup Bin</Styles.Head>
              <Styles.PickupContainer>
                <Styles.Pickup
                  disable={
                    "6,1,2" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "5,1,2" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "4,1,2" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "3,1,2" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "2,1,2" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "1,1,2" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "6,1,1" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "5,1,1" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "4,1,1" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "3,1,1" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "2,1,1" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
                <Styles.Pickup
                  disable={
                    "1,1,1" ===
                    `${startedItem?.pickTask.gate.x},${startedItem?.pickTask.gate.y},${startedItem?.pickTask.gate.z}`
                  }
                >
                  {" "}
                  {startedItem?.pickTask.binId}
                  <Styles.PickIcon>
                    <PickupIcon />
                  </Styles.PickIcon>
                </Styles.Pickup>
              </Styles.PickupContainer>
            </Styles.OrderContainer>
            <Styles.OrderDivider />
          </Styles.OrderDetail>
        </Styles.Content>
      </>
    </Styles.Container>
  );
}
