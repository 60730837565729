import styled from "styled-components";
import { ReactComponent as SelectedIcon } from "../../../../../../assets/icons/selected.svg";

export const Container = styled.div``;
export const Selected = styled(SelectedIcon)`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const Head = styled.div`
  font-size: 30px;
  font-weight: 700;
  color: #1f5454;
  padding-bottom: 10px;
`;

export const Content = styled.div`
  color: #444444;
  font-size: 18px;
  font-weight: 600;
  border-top: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0px;
  margin-bottom: 10px;
`;
