import styled from "styled-components";

export const Container = styled.div`
  margin: 13px 0px;
  background: #f5f5f5;
`;

export const TaskBar = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  min-height: 60px;
  align-items: center;
  padding: 0px 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Item = styled.div`
  background: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:last-child {
    text-align: right;
  }
  span:first-child {
    color: #444444;
    margin-right: 2px;
  }
  span:last-child {
    color: #1f5454;
  }
`;

export const Content = styled.div`
  margin: 13px 0px;
  display: flex;
  gap: 20px;
`;

export const OrderCard = styled.div<{ disable?: boolean }>`
  min-width: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  min-height: 425px;
  padding: 15px;
  height: calc(100vh - 195px);
  position: relative;
  div {
    background: #ffffff;
  }
  button {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 4px !important;
    border-radius: 12px;
    background: #1f5454;
    border: 4px solid #4d9a9a;
    min-height: 60px;
    width: 50%;
  }
  button:disabled {
    background: #cccccc;
    border-color: #cccccc !important;
  }
  button.inc-btn,
  button.dec-btn {
    :hover {
      border: 4px solid #4d9a9a;
    }
  }
  .count,
  .need,
  .exp {
    font-weight: 500;
  }
  .count {
    color: ${(props) => (props.disable === false ? "#444444" : "#07A568")};
  }
  .need {
    color: ${(props) => (props.disable === false ? "#1f5454" : "#07A568")};
  }
  .exp {
    color: #cccccc;
  }
`;
export const IDContainer = styled.div`
  padding-bottom: 20px;
`;

export const LeftButtonContainer = styled.div`
  display: flex;
  gap: 15px;
  position: absolute;
  bottom: 15px;
  width: 90%;
`;
export const ImgContainer = styled.div`
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.12));
  border-radius: 12px;
  height: 150px;
  margin-bottom: 20px;
  text-align: center;
  svg {
    width: 250px;
    height: 150px;
  }
`;

export const QContainer = styled.div`
  display: flex;
  gap: 25px;
`;

export const Labels = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: rgba(68, 68, 68, 0.6);
  padding-bottom: 5px;
`;

export const ItemName = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: #444444;
`;

export const Quantity = styled.div``;

export const SKUId = styled.div``;

export const OrderBtnContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  position: absolute;
  bottom: 0px;
  right: 0px;
`;
export const Head = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: #444444;
  padding-bottom: 15px;
`;
export const Text = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #666666;
`;
export const OrderContainer = styled.div``;
export const DropContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;

export const OrderDivider = styled.div`
  border-bottom: 1px dashed rgba(34, 34, 34, 0.1);
  margin: 15px 0px;
`;

export const PickIcon = styled.div`
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 1px;
    left: -15px;
    height: 100%;
  }
`;
export const PickupDisable = styled.div`
  background: #cccccc;
  border-radius: 8px;
  min-width: 235px;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
`;

export const Pickup = styled.div<{ disable?: boolean }>`
  background: ${(props) => (props.disable === false ? "#CCCCCC" : "#07A568")};
  border-radius: 8px;
  min-width: 235px;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  color: ${(props) => (props.disable === false ? "#CCCCCC" : "#ffffff")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  svg {
    fill: ${(props) => (props.disable === false ? "#ccc" : "#fff")};
  }
  ${PickIcon} {
    &::before {
      background: ${(props) => (props.disable === false ? "#CCCCCC" : "#fff")};
    }
  }

  @media only screen and (min-width: 768px) {
    min-width: 95px;
  }

  @media only screen and (min-width: 992px) {
    min-width: 155px;
    font-size: 12px;
  }
`;

export const Drop = styled.div<{ disable?: boolean }>`
  background: ${(props) => (props.disable === false ? "#4D9A9A" : "#07a568")};
  border-radius: 8px;
  min-width: 235px;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  ${PickIcon} {
    &::before {
      background: ${(props) => (props.disable === false ? "#377E7E" : "#fff")};
    }
  }

  svg {
    fill: ${(props) => (props.disable === false ? "#377E7E" : "#fff")};
  }

  @media only screen and (min-width: 768px) {
    min-width: 95px;
  }

  @media only screen and (min-width: 992px) {
    min-width: 165px;
  }
`;
export const DropDisable = styled.div`
  background: #cccccc;
  border-radius: 8px;
  min-width: 235px;
  height: 45px;
  font-weight: 500;
  font-size: 15px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
`;

export const OrderDetail = styled.div<{
  disable?: boolean;
  doneDisabled?: boolean;
}>`
  width: 100%;
  position: relative;
  button {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    color: rgb(255, 255, 255) !important;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 4px !important;
    border-radius: 12px;
    min-height: 60px;
  }

  button.flag-btn {
    background: #3881e1;
    border: 4px solid #3166ab;
    :hover {
      border: 4px solid #3166ab;
    }
  }
  button.restart-btn {
    background: #ff8b3d;
    border: 4px solid #ff643d;
    :hover {
      border: 4px solid #ff643d;
    }
  }
  button.done-btn {
    pointer-events: ${(props) =>
      props.disable === false || props.doneDisabled === true ? "none" : ""};
    cursor: ${(props) =>
      props.disable === false || props.doneDisabled === true
        ? "default"
        : "pointer"};

    background: ${(props) =>
      props.disable === false || props.doneDisabled === true
        ? "#cccccc"
        : "#07a568"};
    border: 4px solid
      ${(props) =>
        props.disable === false || props.doneDisabled === true
          ? "#cccccc"
          : "#4d9a9a"};
    :hover {
      border: 4px solid
        ${(props) =>
          props.disable === false || props.doneDisabled === true
            ? "#cccccc"
            : "#4d9a9a"};
    }
  }
`;
