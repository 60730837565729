import styled from "styled-components";
import { ReactComponent as SelectedIcon } from "../../../../../../assets/icons/selected.svg";

export const Container = styled.div``;
export const Selected = styled(SelectedIcon)`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;

export const Task = styled.div<{ selected?: boolean }>`
  background-color: ${(props) =>
    props.selected === true ? "#3881e1" : "#1F5454"};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  height: 200px;
  font-weight: 500;
  font-size: 24px;
  text-transform: capitalize;
  color: #ffffff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  padding: 15px 17px;
  position: relative;
  ${Selected} {
    width: ${(props) => (props.selected === true ? "block" : "none")};
  }
`;
