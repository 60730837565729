import { useEffect, useState } from "react";
import * as Styles from "./styles";
import NewTask from "./components/new-task/NewTask";
import NoTask from "./components/no-task/NoTask";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "../../../../common/api/axios";

export default function TaskId() {
  const [getPickItems, setPickItems] = useState<any[]>([]);
  const [PickItemLoading, setPickItemLoading] = useState(false);
  const [time, setTime] = useState(Date.now());
  const navigate = useNavigate();
  // GET Pick Items
  const getPickItemsData = async () => {
    try {
      setPickItemLoading(true);
      const res = await axios.get("PickStation/pickItems", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        setPickItems(res.data);
        setPickItemLoading(false);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    getPickItemsData();
  }, []);

  useEffect(() => {
    if (time) {
      const interval = setInterval(() => {
        setTime(Date.now());
        getPickItemsData();
      }, 30000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [time]);

  const handleChange = (value: string) => {
    if (value === "task-id") {
      navigate("/task-id");
    } else {
      navigate("/order-id");
    }
  };

  return (
    <Styles.Container>
      <Styles.SubHead>
        <Select
          bordered={false}
          className="common"
          popupClassName="pick-dropdown"
          defaultValue="task-id"
          onChange={handleChange}
          options={[
            {
              value: "task-id",
              label: "Order Fulfillment Via Task ID",
            },
            {
              value: "order-id",
              label: "Order Fulfillment Via Order ID",
            },
          ]}
        />
      </Styles.SubHead>
      {getPickItems?.length === 0 ? (
        <NoTask 
          getPickItemsData={getPickItemsData}
        />
      ) : (
        <NewTask
          PickItemLoading={PickItemLoading}
          pickItemsData={getPickItems}
        />
      )}
    </Styles.Container>
  );
}
