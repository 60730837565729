import { Button, Tooltip, Modal, Input } from "antd";
import * as Styles from "./styles";
import { useEffect, useState } from "react";
import RestartPopup from "../restart-popup/RestartPopup";
import FlagPopup from "../flag-popup/FlagPopup";
import { ReactComponent as ImageIcon } from "./icons/product.svg";
import { ReactComponent as ProductIcon } from "./icons/product.svg";
import { ReactComponent as LineWhiteIcon } from "./icons/lineWhite.svg";
import { ReactComponent as PickupWhiteIcon } from "./icons/pickupWhite.svg";
import { ReactComponent as ScanIcon } from "./icons/scan.svg";
import { ReactComponent as InfoIcon } from "./icons/info-icon.svg";
import { ReactComponent as TickIcon } from "./icons/tick-icon.svg";
import { ReactComponent as InfoIconWhite } from "./icons/info-icon-white.svg";
import { ReactComponent as LineIcon } from "./icons/line.svg";
import { ReactComponent as HoldInfoIcon } from "./icons/hold-info.svg";
import { ReactComponent as ProgressInfoIcon } from "./icons/progress-info.svg";
import { ReactComponent as ErrorIcon } from "./icons/info.svg";
import { formatDistance } from "date-fns";
import { message } from "antd";
import { ReactComponent as Toast } from "../../../../../../assets/icons/toast-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import OkPopup from "../ok-popup/Okpopup";
import axios from "../../../../../../common/api/axios";
import { async } from "q";
import { ReactComponent as LoadIcon } from "./icons/loader.svg";

export interface SkuBinProps { pickItemsData?: any, pickStationsFirstData?: any, pickStationsSecondData?: any }

export default function SkuBin({ pickItemsData, pickStationsFirstData, pickStationsSecondData }: SkuBinProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [pickItems, setPickItems] = useState([]);
  const [orderItems, setOrderItems] = useState<any>([]);
  const [isRestartPopup, setIsRestartPopup] = useState(false);
  const [isFlagPopup, setIsFlagPopup] = useState(false);
  const [isOkPopup, setIsOkPopup] = useState(false);
  const [qCount, setQCount] = useState(0);
  const [allQty, setAllQty] = useState(0);
  const [doneLoading, setDoneLoading] = useState(false);
  const [okayLoading, setOkayLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [orderQuantity, setOrderQuantity] = useState<any>([]);
  const [itemsData, setItemsData] = useState<any>([]);
  const [state, setState] = useState("");

  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFlagModalVisible, setIsFlagModalVisible] = useState(false);
  const [flagRemarks, setFlagRemarks] = useState("");

  const totalOrderCount = orderItems.reduce(
    (total: any, currentValue: any) =>
      (total = total + currentValue.pickTask.quantity),
    0
  );
  useEffect(() => {
    setPickItems(pickItemsData);
  }, [pickItemsData])
  /* const firstRow = ["5,4,2", "4,4,2", "3,4,2", "2,4,2", "1,4,2"];
  const secondRow = ["5,4,1", "4,4,1", "3,4,1", "2,4,1", "1,4,1"]; */
  const firstRow = pickStationsFirstData;
  const secondRow = pickStationsSecondData;
  useEffect(() => {
    if (pickItems?.length !== 0 && pickItemsData.length !== orderItems?.length) {
      let gateVal: any = [];
      const new_overAllData = pickItemsData.map((val: any) => {
        /* let skuDetails = [];
        const res:any = axios.get(`/MasterData/sku/${val.pickTask.skuId}`, {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        });
        if (res.status === 200) {
          console.log('res--',res)
          skuDetails.push(res.data);
          
        } */
        const orderPlace = Object.values(val.pickTask.gate);
        const joinedItem = orderPlace.join(",");

        let qty = val.pickTask.quantity;
        if (gateVal.includes(joinedItem)) {
          qty = qty + 1;
        }
        gateVal.push(joinedItem);
        return {
          ...val,
          position: joinedItem,
          orderColor: "default",
          selectedQty: "00",
          completedStatus: false,
          okLoader: false,
          qty: qty,
          skuDetails: []
        };
        //val.checked = false;
      });
      setItemsData(new_overAllData);
      setOrderItems(new_overAllData);
      setSelectedOrder([]);
    }
  });

  // POST Pick Items for order fulfillment
  const onHandleDoneButton = () => {
    setDoneLoading(true);
    //setTimeout(() => {
    setDoneLoading(false);
    messageApi.open({
      content: "Pick-task Complete !",
      icon: <Toast />,
      className: "order",
      style: {
        marginTop: "85vh",
      },
    });
    //}, 2000);
    /* setTimeout(() => {
      console.log('data---')
      navigate("/order-id");
    }, 1000); */
  };

  //Each item for Ok button api
  const ohHandleOkItem = async (id: string, Index: any) => {

    setItemsData((current: any) =>
      itemsData.map((obj: any, key: any) => {
        if (obj.id === id) {
          return {
            ...obj,
            okLoader: true,
          };
        }
        return obj;
      })
    );
    setOkayLoading(true);
    setSelectedOrder(Index[0]);
    setSelectedTaskId(Index[0].id);
    setSelectedDate(Index[0].creationDate);

    try {
      const res = await axios.put(`/PickStation/signalPick/${id}`, null, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });

      if (res.status === 200) {
        messageApi.open({
          content: "Pick-task Complete !",
          icon: <Toast />,
          className: "order",
          style: {
            marginTop: "85vh",
          },
        });
        setAllQty(
          (prevState) => prevState + parseInt(selectedOrder?.pickTask.quantity)
        );
        let sliceData = itemsData;
        await sliceData.splice(sliceData.findIndex((obj: any) => obj.id === id), 1);
        setItemsData(sliceData)
        /* setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === id) {
              return {
                ...obj,
                completedStatus: true,
                okLoader: false,
              };
            }
            return obj;
          })
        ); */
        setOkayLoading(false);
        setSelectedOrder([]);
      }
    } catch (error: any) {
      setErrorMessage(error.response.data);
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === id) {
            return {
              ...obj,
              okLoader: false,
            };
          }
          return obj;
        })
      );
      setIsOkPopup(true);
      setOkayLoading(false);
    }
  };

  const isOpenRestartPopup = () => {
    setIsRestartPopup(true);
  };

  const isCloseRestartPopup = () => {
    setIsRestartPopup(false);
  };

  const isOpenFlagPopup = () => {
    setIsFlagPopup(true);
  };

  const isCloseFlagPopup = () => {
    setIsFlagPopup(false);
  };

  const isOpenOkPopup = (e: any, taskId: string, Index: any) => {
    e.stopPropagation();
    ohHandleOkItem(taskId, Index);
  };

  const isCloseOkPopup = () => {
    //ohHandleOkItem(selectedOrder.id);
    setIsOkPopup(false);
  };
  const isCancelOkPopup = () => {
    setIsOkPopup(false);
  };

  const dateTimeFormat = (time: string) => {
    const distance = formatDistance(Date.now(), new Date(time), {
      addSuffix: true,
    });
    const firstWord = distance.replace(/in/g, "");
    const finalWord = firstWord.replace(/about/g, "");
    const formatted = finalWord.replace(/mutes/g, "Mins");
    const one = formatted.replace(/mute/g, "Minute");
    const two = one.replace(/ago/g, "");
    return two;
  };

  const onHandleOrderItem = async (orderItem: any) => {
    let skuDetails: any = [];
    const res: any = await axios.get(`/MasterData/sku/${orderItem[0].pickTask.skuId}`, {
      headers: {
        Accept: "application/json",
        tenantId: 1,
      },
    });
    if (res.status === 200) {
      skuDetails.push(res.data);

    }
    //setSelectedOrder(orderItem[0]);
    setSelectedTaskId(orderItem[0].id);
    setSelectedDate(orderItem[0].creationDate);
    let newOrderQuantity = [];
    newOrderQuantity[orderItem[0].pickTask.skuId] = 1;
    setQCount(0);
    setItemsData((current: any) =>
      itemsData.map((obj: any, key: any) => {
        if (obj.id === orderItem[0].id && obj.orderColor !== "completed") {
          if (obj.orderColor === "default") {
            let cntVal =
              orderItem[0].qty < 10
                ? ("0" + `${orderItem[0].qty}`).slice(-2)
                : orderItem[0].qty;
            setSelectedOrder((current: any) => {
              return {
                ...orderItem[0],
                qty: cntVal,
                selectedQty: cntVal,
                skuDetails: skuDetails
              };
            });
            return {
              ...obj,
              orderColor: "completed",
              qty: cntVal,
              selectedQty: cntVal,
              skuDetails: skuDetails
            };
          } else {
            setSelectedOrder((current: any) => {
              return {
                ...orderItem[0],
                skuDetails: skuDetails
              };
            });
            return {
              ...obj,
              orderColor: "progress",
              skuDetails: skuDetails
            };
          }
        } else {
          if (obj.orderColor === "progress" && obj.selectedQty > 0) {
            return {
              ...obj,
              orderColor: "hold",
            };
          } else {
            return {
              ...obj,
              orderColor:
                obj.orderColor === "" || obj.orderColor === "progress"
                  ? ""
                  : obj.orderColor,
            };
          }
        }
        // return obj;
      })
    );
    /* setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === orderItem[0].id) {
              return {
                ...obj,
                orderColor: "completed",
                selectedQty: cntVal,
              };
            }
            return obj;
          })
        ); */
  };
  const addQty = (qCount: number) => {
    setQCount(qCount);
    //setAllQty((prevState) => prevState + 1);
    let cntVal: any = qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount;

    setSelectedOrder((current: any) => {
      return {
        ...current,
        selectedQty: cntVal,
      };
    });
    if (qCount === selectedOrder?.pickTask?.quantity) {
      setState("completed");

      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              orderColor: "completed",
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    } else {
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    }
  };
  const removeQty = (qCount: number) => {
    //setAllQty((prevState) => prevState - 1);
    setQCount(qCount);

    let cntVal = qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount;
    setSelectedOrder((current: any) => {
      return {
        ...current,
        selectedQty: cntVal,
      };
    });
    if (qCount < selectedOrder?.pickTask?.quantity) {
      setState("completed");

      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              orderColor: "progress",
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    } else {
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    }
  };

  const onHandleRestart = () => {
    window.location.reload();
  };

  const quantityNeededCount =
    qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount;


  const handleFlagButtonClick = () => {
    setIsFlagModalVisible(true);
  };

  const handleFlagModalClose = () => {
    setIsFlagModalVisible(false);
    setFlagRemarks("");
  };

  const handleSkuIssue = async () => {
    if (selectedOrder) {
      try {
        const remarks: string = flagRemarks.trim() || 'SKU issue';
        const response = await axios.put(
          `/MasterData/sku/flag/${selectedOrder.pickTask.skuId}/true/${remarks}`,
          null,
          {
            headers: {
              Accept: "application/json",
              tenantId: 1,
            },
          }
        );

        const binResponse = await axios.put(
          `/BinMaster/flag/${selectedOrder.pickTask.binId}/true/${remarks}`,
          null,
          {
            headers: {
              Accept: "application/json",
              tenantId: 1,
            },
          }
        );

        if (response.status === 200 && binResponse.status === 200) {
          messageApi.success("SKU flagged successfully");
        }
      } catch (error) {
        messageApi.error("Failed to flag SKU");
      }
    }
    handleFlagModalClose();
  };

  const handleBinIssue = async () => {
    if (selectedOrder) {
      try {
        const binId = selectedOrder.pickTask.binId;
        const remarks: string = flagRemarks.trim() || 'Bin issue';
        await axios.put(
          `/BinMaster/flag/${binId}/true/${remarks}`,
          null,
          {
            headers: {
              Accept: "application/json",
              tenantId: 1,
            },
          }
        );

        const binItemsResponse = await axios.get(`/BinMaster/items/${binId}`, {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        });

        if (binItemsResponse.status === 200) {
          const binItems = binItemsResponse.data;
          for (const item of binItems) {
            await axios.put(
              `/MasterData/sku/flag/${item.skuId}/true/${remarks}`,
              null,
              {
                headers: {
                  Accept: "application/json",
                  tenantId: 1,
                },
              }
            );
          }
        }

        messageApi.success("Bin and associated SKUs flagged successfully");
      } catch (error) {
        messageApi.error("Failed to flag Bin and associated SKUs");
      }
    }
    handleFlagModalClose();
  };

  return (
    <>
      <Styles.Container className="order-detail">
        <>
          <Styles.TaskBar>
            <Styles.TaskBarInner>
              <Styles.Item>
                <span>Order ID:</span>
                <span>{orderItems[0]?.pickTask.orderReferenceId}</span>
              </Styles.Item>
              {selectedTaskId !== "" && (
                <Styles.Item>
                  <span>Task ID:</span>
                  <span>{selectedTaskId}</span>
                </Styles.Item>
              )}
            </Styles.TaskBarInner>
            <Styles.TaskBarInner>
              <Styles.Item className="timestamp">
                {selectedDate !== "" && (
                  <span>Placed @ {dateTimeFormat(selectedDate)} Ago</span>
                )}
              </Styles.Item>
              <Styles.ItemText countReached={false}>
                <span>Items : </span>
                <span>
                  {allQty < 10 ? ("0" + `${allQty}`).slice(-2) : allQty} /{" "}
                  {totalOrderCount < 10
                    ? ("0" + `${totalOrderCount}`).slice(-2)
                    : totalOrderCount}
                </span>
              </Styles.ItemText>
            </Styles.TaskBarInner>
          </Styles.TaskBar>
          <Styles.Content>
            {selectedOrder?.length === 0 ? (
              <Styles.OrderCard className="empty">
                {/* <Styles.ImgContainer className="left-img">
                   Please, Select
                  <br />
                  the Product Location
                </Styles.ImgContainer> */}
                <Styles.ScanText>
                  Please, Select
                  <br />
                  the Product Location
                </Styles.ScanText>
              </Styles.OrderCard>
            ) : (
              <Styles.OrderCard
                disable={qCount === selectedOrder?.pickTask.quantity}
                increment={qCount > 0}
              >
                <Styles.ImgContainer className="left">
                  {selectedOrder?.skuDetails[0]?.imageUri && selectedOrder?.skuDetails[0]?.imageUri !== "" ? (
                    <img src={selectedOrder?.skuDetails[0]?.imageUri} alt="product image" />
                  ) : (
                    <ImageIcon />
                  )}
                  {/* <ImageIcon /> */}
                </Styles.ImgContainer>
                <Styles.IDContainer>
                  <Styles.Labels>Item Name:</Styles.Labels>
                  <Tooltip
                    title={
                      selectedOrder?.skuDetails[0].productName &&
                        selectedOrder?.skuDetails[0].productName !== ""
                        ? selectedOrder?.skuDetails[0].productName
                        : ""
                    }
                  >
                    <Styles.ItemName className="description">
                      {selectedOrder?.skuDetails[0].productName &&
                        selectedOrder?.skuDetails[0].productName !== ""
                        ? selectedOrder?.skuDetails[0].productName
                        : ""}
                    </Styles.ItemName>
                  </Tooltip>
                  {/*  <Styles.ItemName>
                    {selectedOrder?.pickTask.sku}
                  </Styles.ItemName> */}
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Styles.Labels>Description:</Styles.Labels>
                  <Tooltip title={selectedOrder?.skuDetails[0].description}>
                    <Styles.ItemName className="description">
                      {selectedOrder?.skuDetails[0].description}
                    </Styles.ItemName>
                  </Tooltip>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Styles.QContainer>
                    <div>
                      <Styles.Labels>Qty Count</Styles.Labels>
                      <Styles.ItemName className="count">
                        {selectedOrder?.selectedQty}
                      </Styles.ItemName>
                    </div>
                    <div>
                      <Styles.Labels>Qty Needed</Styles.Labels>
                      <Styles.ItemName className="need">
                        {selectedOrder?.qty !== undefined &&
                          selectedOrder?.qty < 10
                          ? ("0" + `${selectedOrder?.qty}`).slice(
                            -2
                          )
                          : selectedOrder?.qty}
                      </Styles.ItemName>
                    </div>
                    <div>
                      <Styles.Labels>Exp Date</Styles.Labels>
                      <Styles.ItemName className="exp">-NA-</Styles.ItemName>
                    </div>
                  </Styles.QContainer>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Styles.Labels>
                    SKU ID:
                  </Styles.Labels>
                  <Styles.ItemName>
                    {selectedOrder?.pickTask.skuId}
                  </Styles.ItemName>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Button
                    className="flag-btn"
                    onClick={handleFlagButtonClick}
                    style={{
                      fontSize: '1em',
                      backgroundColor: '#FFA500',
                      borderColor: '#FFA500',
                      color: 'white',
                      width: '45%'
                    }}
                  >
                    Flag SKU or Bin
                  </Button>
                </Styles.IDContainer>
                <Styles.LeftButtonContainer>
                  <Button
                    className="dec-btn"
                    disabled={selectedOrder?.selectedQty == 0}
                    onClick={() =>
                      removeQty(parseInt(selectedOrder?.selectedQty) - 1)
                    }
                  >
                    -
                  </Button>
                  <Button
                    className="inc-btn"
                    onClick={() =>
                      addQty(parseInt(selectedOrder?.selectedQty) + 1)
                    }
                    disabled={
                      parseInt(selectedOrder?.selectedQty) ===
                      parseInt(selectedOrder?.qty)
                    }
                  >
                    +
                  </Button>
                </Styles.LeftButtonContainer>
              </Styles.OrderCard>
            )}

            <Styles.OrderDetail doneDisabled={allQty !== 0}>
              <Styles.OrderContainer>
                <Styles.Head>
                  {selectedOrder?.length === 0 ? (
                    <span>Pickup Items</span>
                  ) : (
                    <>
                      <span>Pickup Item:</span>&nbsp;
                      <span>
                        {selectedOrder !== null && selectedOrder.pickTask.sku}
                      </span>
                    </>
                  )}
                </Styles.Head>
                <Styles.PickupContainer disable={okayLoading} state={firstRow.length}>
                  {firstRow.map((val: any) => {
                    const Index = itemsData.filter(
                      (item: any) => item.position === val
                    );

                    return (
                      <>
                        {Index.length > 0 ? (
                          <Styles.Pickup
                            state={Index[0].orderColor}
                            onClick={() =>
                              Index[0].completedStatus === false &&
                              onHandleOrderItem(Index)
                            }
                            okDisable={
                              Index[0].selectedQty > 0 &&
                                Index[0].completedStatus === false
                                ? false
                                : true
                            }
                          >
                            <div className="main">
                              <div className="second">

                                <Styles.PickImage>
                                  {Index[0].skuDetails.length > 0 && Index[0].skuDetails[0].imageUri ? (
                                    <img src={Index[0].skuDetails[0].imageUri} alt="box image" />
                                  ) : (
                                    <ProductIcon />
                                  )}
                                </Styles.PickImage>
                                <Styles.PickIcon color={Index[0].orderColor}>
                                  <Styles.Qty state={Index[0].orderColor}>
                                    {Index[0].selectedQty}/{" "}
                                    {Index[0].qty < 10
                                      ? (
                                        "0" + `${Index[0].qty}`
                                      ).slice(-2)
                                      : Index[0].qty}
                                  </Styles.Qty>
                                  {Index[0].orderColor === "completed" ? (
                                    Index[0].completedStatus === true ? (
                                      <TickIcon />
                                    ) : (
                                      <InfoIconWhite />
                                    )
                                  ) : Index[0].orderColor === "hold" ? (
                                    <HoldInfoIcon />
                                  ) : Index[0].orderColor === "progress" ? (
                                    <ProgressInfoIcon />
                                  ) : (
                                    <InfoIcon />
                                  )}

                                  <Button
                                    className="ok-btn"
                                    onClick={(e) =>
                                      isOpenOkPopup(e, Index[0]?.id, Index)
                                    }
                                    //disabled={Index[0].completedStatus}
                                    disabled={
                                      Index[0].selectedQty > 0 &&
                                        Index[0].completedStatus === false
                                        ? false
                                        : true
                                    }
                                  >
                                    {Index[0].okLoader === true ? (
                                      <LoadIcon />
                                    ) : (
                                      "OK"
                                    )}
                                  </Button>
                                </Styles.PickIcon>

                              </div>
                              <div>
                                {/* <Styles.DropContainer> */}
                                <Styles.DropSlot
                                  disable={
                                    Index[0].orderColor
                                  }
                                >
                                  Slot - {Index[0].position !== ""
                                    ? Index[0].position
                                    : "- NA - "}

                                  <Styles.DropIcon>
                                    <PickupWhiteIcon />
                                  </Styles.DropIcon>
                                </Styles.DropSlot>
                                {/* </Styles.DropContainer> */}
                              </div>
                            </div>
                          </Styles.Pickup>
                        ) : (
                          <Styles.Pickup className="no-data"></Styles.Pickup>
                        )}
                      </>
                    );
                  })}

                  {secondRow.map((val: any) => {
                    const Index = itemsData.slice().reverse().filter(
                      (item: any) => item.position === val
                    );
                    return (
                      <>
                        {Index.length > 0 ? (
                          <Styles.Pickup
                            state={Index[0].orderColor}
                            onClick={() =>
                              Index[0].completedStatus === false &&
                              onHandleOrderItem(Index)
                            }
                            okDisable={
                              Index[0].selectedQty > 0 &&
                                Index[0].completedStatus === false
                                ? false
                                : true
                            }
                          >
                            <div className="main">
                              <div className="second">
                                <Styles.PickImage>
                                  {Index[0].skuDetails.length > 0 && Index[0].skuDetails[0].imageUri ? (
                                    <img src={Index[0].skuDetails[0].imageUri} alt="box image" />
                                  ) : (
                                    <ProductIcon />
                                  )}
                                </Styles.PickImage>
                                <Styles.PickIcon color={Index[0].orderColor}>
                                  <Styles.Qty state={Index[0].orderColor}>
                                    {Index[0].selectedQty} / {Index[0].okLoader}
                                    {Index[0].qty < 10
                                      ? (
                                        "0" + `${Index[0].qty}`
                                      ).slice(-2)
                                      : Index[0].qty}
                                  </Styles.Qty>
                                  {Index[0].orderColor === "completed" ? (
                                    Index[0].completedStatus === true ? (
                                      <TickIcon />
                                    ) : (
                                      <InfoIconWhite />
                                    )
                                  ) : Index[0].orderColor === "hold" ? (
                                    <HoldInfoIcon />
                                  ) : Index[0].orderColor === "progress" ? (
                                    <ProgressInfoIcon />
                                  ) : (
                                    <InfoIcon />
                                  )}
                                  <Button
                                    className="ok-btn"
                                    onClick={(e) =>
                                      isOpenOkPopup(e, Index[0]?.id, Index)
                                    }
                                    disabled={
                                      Index[0].selectedQty > 0 &&
                                        Index[0].completedStatus === false
                                        ? false
                                        : true
                                    }
                                  >
                                    {Index[0].okLoader === true ? (
                                      <LoadIcon />
                                    ) : (
                                      "OK"
                                    )}
                                  </Button>
                                </Styles.PickIcon>
                              </div>
                              <div>
                                {/* <Styles.DropContainer> */}
                                <Styles.DropSlot
                                  disable={
                                    Index[0].orderColor
                                  }
                                >
                                  Slot - {Index[0].position !== ""
                                    ? Index[0].position
                                    : "- NA - "}

                                  <Styles.DropIcon>
                                    <PickupWhiteIcon />
                                  </Styles.DropIcon>
                                </Styles.DropSlot>
                                {/* </Styles.DropContainer> */}
                              </div>
                            </div>
                          </Styles.Pickup>
                        ) : (
                          <Styles.Pickup></Styles.Pickup>
                        )}
                      </>
                    );
                  })}
                </Styles.PickupContainer>
              </Styles.OrderContainer>

              <Styles.OrderBtnContainer>
                <Button
                  className="done-btn"
                  loading={doneLoading}
                  onClick={() => onHandleDoneButton()}
                >
                  {doneLoading === true ? "Loading..." : "Done"}
                </Button>
              </Styles.OrderBtnContainer>
            </Styles.OrderDetail>
          </Styles.Content>
          <RestartPopup
            visible={isRestartPopup}
            isCloseRestartPopup={isCloseRestartPopup}
          />
          <FlagPopup
            visible={isFlagPopup}
            isCloseFlagPopup={isCloseFlagPopup}
          />
          <OkPopup
            visible={isOkPopup}
            isCloseOkPopup={isCloseOkPopup}
            isRestart={onHandleRestart}
            isCancel={isCancelOkPopup}
            errorMessage={errorMessage}
          />
        </>
      </Styles.Container>
      <>{contextHolder}</>

      <Modal
        title="Flag SKU or Bin"
        visible={isFlagModalVisible}
        onCancel={handleFlagModalClose}
        footer={null}
      >
        <Input.TextArea
          placeholder="Enter remarks"
          value={flagRemarks}
          onChange={(e) => setFlagRemarks(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <Button onClick={handleSkuIssue} style={{ marginRight: 8 }}>
          SKU Issue
        </Button>
        <Button onClick={handleBinIssue}>
          Bin Issue
        </Button>
      </Modal>
    </>
  );
}
