import { Button, Input, Tooltip } from "antd";
import type { InputRef } from "antd";
import * as Styles from "./styles";
import { useEffect, useState, useRef } from "react";
import { ReactComponent as ImageIcon } from "./icons/product.svg";
import { ReactComponent as ProductIcon } from "./icons/product.svg";
import { ReactComponent as LineWhiteIcon } from "./icons/lineWhite.svg";
import { ReactComponent as PickupWhiteIcon } from "./icons/pickupWhite.svg";
import { ReactComponent as ScanIcon } from "./icons/scan.svg";
import { ReactComponent as InfoIcon } from "./icons/info-icon.svg";
import { ReactComponent as TickIcon } from "./icons/tick-icon.svg";
import { ReactComponent as InfoIconWhite } from "./icons/info-icon-white.svg";
import { ReactComponent as LineIcon } from "./icons/line.svg";
import { ReactComponent as HoldInfoIcon } from "./icons/hold-info.svg";
import { ReactComponent as ProgressInfoIcon } from "./icons/progress-info.svg";
// import { ReactComponent as ErrorIcon } from "./icons/info.svg";
import { ReactComponent as PickupIcon } from "./icons/pickup.svg";
import { ReactComponent as ErrorIcon } from "./icons/error.svg";
import { ReactComponent as CardLoaderIcon } from "./icons/loader.svg";

import { formatDistance } from "date-fns";
import { message } from "antd";
import { ReactComponent as Toast } from "../../../../assets/icons/toast-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import OkPopup from "../ok-popup/OkPopup";
import axios from "../../../../common/api/axios";
import { async } from "q";
import { ReactComponent as LoadIcon } from "./icons/loader.svg";
import MaterialInward from "../../MaterialInward";

export interface PickupToteProps {pickItemsData?:any, pickStationsData?: any}

export default function PickupTote({pickItemsData, pickStationsData}: PickupToteProps) {
  const inputRef = useRef<InputRef>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [pickItems, setPickItems] = useState([]);
  const [orderItems, setOrderItems] = useState<any>([]);
  const [isOkPopup, setIsOkPopup] = useState(false);
  const [qCount, setQCount] = useState(0);
  const [allQty, setAllQty] = useState(0);
  const [doneLoading, setDoneLoading] = useState(false);
  const [okayLoading, setOkayLoading] = useState(false);
  const [doneDisable, setDoneDisable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [orderQuantity, setOrderQuantity] = useState<any>([]);
  const [itemsData, setItemsData] = useState<any>([]);
  const [state, setState] = useState("");
  const [pickHighlight, setPickHighlight] = useState(false);
  const [binId, setBinId] = useState("");
  const [toteInput, setToteInput] = useState(true);
  const [navigationEnable, setNavigationEnable]= useState(false);
  const [activeTote, setActiveTote] = useState<any>([]);

  const [selectedTaskId, setSelectedTaskId] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const totalOrderCount = orderItems.reduce(
    (total: any, currentValue: any) =>
      (total = total + currentValue.inwardTask.quantity),
    0
  );
  //const firstRow = ["11,4,1", "9,4,1", "8,4,1"];
  const firstRow = pickStationsData;
  useEffect(() => {
    setPickItems(pickItemsData);
  }, [pickItemsData])
  
  useEffect(() => {
    if (pickItems?.length !== 0 && pickItemsData.length !== orderItems?.length) {
      let LocalData:any = localStorage.getItem('completedInwards');
      LocalData = LocalData ? JSON.parse(LocalData) : []
      const new_overAllData = pickItemsData.map((val: any) => {
        let completeData = LocalData.find((Loc:any) => Loc.taskId === val.id)
        const orderPlace = Object.values(val.inwardTask.from);
        const joinedItem = orderPlace.join(",");
        const completedStatus = completeData ? true : false;
        return {
          ...val,
          position: joinedItem,
          orderColor: completedStatus ? "completed" :"default",
          selectedQty: "00",
          okLoader: false,
          loaderStatus: false,
          completedStatus: completedStatus,
          toteID: "",
        };
      });
      setItemsData(new_overAllData);
      setOrderItems(new_overAllData);
      setSelectedOrder([]);
      LocalData.forEach((obj:any) => 
        {
         if(pickItemsData.some( (val:any) => obj.taskId === val.id) === false)
          {
            let updatedData = LocalData.filter( (dat:any) => dat.taskId !== obj.taskId);
            localStorage.setItem('completedInwards',JSON.stringify(updatedData))
          } 
        });
      }
    });

  const onHandleDoneButton = () => {
    setDoneLoading(true);
    //setTimeout(() => {
      setDoneLoading(false);
      messageApi.open({
        content: "Inward task completed !",
        icon: <Toast />,
        className: "order",
        style: {
          marginTop: "85vh",
        },
      });
    //}, 2000);
    setNavigationEnable(true);
    //window.history.replaceState({}, '/material-inward')

    //navigate("/material-inward");
    /* setTimeout(() => {
      
    }, 1500); */
  };

  //Each item for Ok button api
  const ohHandleOkItem = async (id: string) => {
    let completedInwards:any = localStorage.getItem('completedInwards') ? localStorage?.getItem('completedInwards') : [];
        let inwards:any = {
          taskId: id,
          isCompleted: true
        }
        completedInwards = Array.isArray(completedInwards) ? completedInwards : JSON.parse(completedInwards);
        completedInwards.push(inwards)
        localStorage.setItem('completedInwards',JSON.stringify(completedInwards))
    setItemsData((current: any) =>
      itemsData.map((obj: any, key: any) => {
        if (obj.id === id) {
          return {
            ...obj,
            okLoader: true,
          };
        }
        return obj;
      })
    );
    setOkayLoading(true);
    try {
      const res = await axios.put(
        `/PickStation/updateInwardTaskQuantityAndComplete/${id}/${qCount}`,
        null,
        {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        }
      );
      if (res.status === 200) {
        setToteInput(true);
        messageApi.open({
          content: "Item Completed !",
          icon: <Toast />,
          className: "order",
          style: {
            marginTop: "85vh",
          },
        });
        setAllQty((prevState) => prevState + qCount);
        /* let sliceData = itemsData;
        await sliceData.splice(sliceData.findIndex((obj: any) => obj.id === id) , 1);
        setItemsData(sliceData) */
        setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === id) {
              return {
                ...obj,
                orderColor: "completed",
                completedStatus: true,
                okLoader: false,
              };
            }
            return obj;
          })
        );
        
        setSelectedOrder([]);
        setBinId("");
        setSelectedTaskId("");
        setSelectedDate("");
        setOkayLoading(false);
      }
    } catch (error: any) {
      setErrorMessage(error.response.data);
      setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === id) {
              return {
                ...obj,
                okLoader: false,
              };
            }
            return obj;
          })
        );
      setIsOkPopup(true);
      setOkayLoading(false);
    }
  };

  //SKU Extract
  const onExtractSku = async (
    skuId: string,
    qty: number,
    id: string,
    binVal: string
  ) => {
    try {
      const res = await axios.get(`/MasterData/sku/${skuId}`, {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        if(binVal === ''){
        setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === id) {
              return {
                ...obj,
                /* orderColor: "completed",
                selectedQty: cntVal,
                loaderStatus: false,
                toteID: binVal, */
                prodcutName: res?.data?.productName,
                image: res?.data?.imageUri,
              };
            }
            return obj;
          })
        );
        }
        else{
          let cntVal: any = qty < 10 ? ("0" + `${qty}`).slice(-2) : qty;
          setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === id) {
              return {
                ...obj,
                orderColor: "completed",
                selectedQty: cntVal,
                loaderStatus: false,
                toteID: binVal,
                image: res?.data?.imageUri,
              };
            }
            return obj;
          })
        );
          setSelectedOrder((current: any) => {
            return {
              ...res.data,
              quantity: qty,
              selectedQty: cntVal,
              id: id,
            };
          });
          setQCount(qty); 
        }
        // setAllQty((prevState) => prevState + qty);
      }
    } catch (error: any) {
      messageApi.open({
        type: "error",
        content: `Server Error !`,
        icon: <ErrorIcon />,
        className: "error",
        style: {
          marginTop: "85vh",
        },
      });
    }
  };

  const isOpenOkPopup = (taskId: string) => {
    ohHandleOkItem(taskId);
  };

  const isCloseOkPopup = () => {
    ohHandleOkItem(selectedOrder.id);
    setIsOkPopup(false);
  };

  const isCancelOkPopup = () => {
    setIsOkPopup(false);
  };

  const dateTimeFormat = (time: string) => {
    const distance = formatDistance(Date.now(), new Date(time), {
      addSuffix: true,
    });
    const firstWord = distance.replace(/in/g, "");
    const finalWord = firstWord.replace(/about/g, "");
    const formatted = finalWord.replace(/mutes/g, "Mins");
    const one = formatted.replace(/mute/g, "Minute");
    const two = one.replace(/ago/g, "");
    return two;
  };

  const onHandleOrderItem = (orderItem: any) => {
    setTimeout(() => {
      inputRef.current!.focus();
    }, 500);

    if (orderItem[0].toteID !== "") {
      setSelectedOrder(orderItem[0]);
      let newOrderQuantity = [];
      newOrderQuantity[orderItem[0].inwardTask.skuId] = 1;
      setQCount(0);
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === orderItem[0].id && obj.orderColor !== "completed") {
            return {
              ...obj,
              orderColor: "progress",
            };
          } else {
            if (obj.orderColor === "progress" && obj.selectedQty > 0) {
              return {
                ...obj,
                orderColor: "hold",
              };
            } else {
              return {
                ...obj,
                orderColor:
                  obj.orderColor === "" || obj.orderColor === "progress"
                    ? ""
                    : obj.orderColor,
              };
            }
          }
        })
      );
    } else {
      setToteInput(false);
      setActiveTote(orderItem[0]);
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === orderItem[0].id && obj.orderColor !== "completed") {
            return {
              ...obj,
              orderColor: "progress",
            };
          } else {
            if (obj.orderColor === "progress" && obj.selectedQty > 0) {
              return {
                ...obj,
                orderColor: "hold",
              };
            } else {
              return {
                ...obj,
                orderColor:
                  obj.orderColor === "" || obj.orderColor === "progress"
                    ? ""
                    : obj.orderColor,
              };
            }
          }
        })
      );
      onExtractSku(
        orderItem[0].inwardTask.skuId,
        orderItem[0].inwardTask.quantity,
        orderItem[0].id,
        ''
      );
    }
  };
  const addQty = (qCount: number) => {
    setQCount(qCount);
    //setAllQty((prevState) => prevState + 1);
    let cntVal: any = qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount;

    setSelectedOrder((current: any) => {
      return {
        ...current,
        selectedQty: cntVal,
      };
    });
    if (qCount === selectedOrder?.quantity) {
      setState("completed");

      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              orderColor: "completed",
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    } else {
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.inwardTask.skuId === selectedOrder.skuId) {
            return {
              ...obj,
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    }
  };

  const removeQty = (qCount: number) => {
    //setAllQty((prevState) => prevState - 1);
    setQCount(qCount);

    let cntVal = qCount < 10 ? ("0" + `${qCount}`).slice(-2) : qCount;
    setSelectedOrder((current: any) => {
      return {
        ...current,
        selectedQty: cntVal,
      };
    });

    if (qCount < selectedOrder?.quantity) {
      setState("completed");

      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              orderColor: "progress",
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    } else {
      setItemsData((current: any) =>
        itemsData.map((obj: any, key: any) => {
          if (obj.id === selectedOrder.id) {
            return {
              ...obj,
              selectedQty: cntVal,
            };
          }
          return obj;
        })
      );
    }
  };

  const onHandleRestart = () => {
    window.location.reload();
  };

  //gate location mapping
  const onLocationMapping = async (index: any, binVal: any) => {
    try {
      const res = await axios.put(
        `/PickStation/updateInwardTask/${index.id}/${binVal}`,
        null,
        {
          headers: {
            Accept: "application/json",
            tenantId: 1,
          },
        }
      );
      if (res.status === 200) {
        messageApi.open({
          content: `Selected gate located this ${binVal} Id !`,
          icon: <Toast />,
          className: "order",
          style: {
            marginTop: "85vh",
          },
        });
        setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === index.id) {
              return {
                ...obj,
                loaderStatus: true,
              };
            }
            return obj;
          })
        );
        onExtractSku(
          index.inwardTask.skuId,
          index.inwardTask.quantity,
          index.id,
          binVal
        );
        setSelectedTaskId(index.id);
        setSelectedDate(index.creationDate);
      }
    } catch (error: any) {
      messageApi.open({
        type: "error",
        content: `${binVal} ID not located this gate !`,
        icon: <ErrorIcon />,
        className: "error",
        style: {
          marginTop: "85vh",
        },
      });
    }
  };

  const changeBin = (val: React.KeyboardEvent<HTMLInputElement>) => {
    // setBinId(val);
    onLocationMapping(activeTote, val.currentTarget.value);
    /* setItemsData((current: any) =>
          itemsData.map((obj: any, key: any) => {
            if (obj.id === activeTote?.id) {
              return {
                ...obj,
                toteID: val,
              };
            }
            return obj;
          })
        ); */
  };

  const renderTooltip = (ID:any,name:any) => {
    return (
      <>
         <p>SKU ID : {ID}</p>
        {name &&
         <p>SKU Name : {name}</p>
        }
    </>
   )
   }

  return (
    navigationEnable ?
    <MaterialInward 
    taskCount={pickItemsData.length}
    taskDetails={pickItemsData} /> 
    :
    <>
      <Styles.Container className="order-detail">
        <>
          <Styles.TaskBar>
            <Styles.TaskBarInner>
              {selectedTaskId !== "" && (
                <Styles.Item>
                  <span>Task ID:</span>
                  <span>{selectedTaskId}</span>
                </Styles.Item>
              )}
            </Styles.TaskBarInner>
            <Styles.TaskBarInner>
              <Styles.Item className="timestamp">
                {selectedDate !== "" && (
                  <span>Placed @ {dateTimeFormat(selectedDate)} Ago</span>
                )}
              </Styles.Item>
              <Styles.ItemText countReached={false}>
                <span>Items : </span>
                <span>
                  {allQty < 10 ? ("0" + `${allQty}`).slice(-2) : allQty} /
                  {totalOrderCount < 10
                    ? ("0" + `${totalOrderCount}`).slice(-2)
                    : totalOrderCount}
                </span>
              </Styles.ItemText>
            </Styles.TaskBarInner>
          </Styles.TaskBar>
          <Styles.Content>
            {selectedOrder?.length === 0 ? (
              <Styles.OrderCard>
                <Styles.ImgContainer className="left-img">
                  <ScanIcon />
                </Styles.ImgContainer>
                <div>
                  <Styles.ScanText>
                    Scan the selected <br />
                    Pickup Tote’s barcode
                    <br /> to continue
                  </Styles.ScanText>
                  <Styles.Or>- or -</Styles.Or>
                  <Input
                    className="tote-input"
                    name="pickup-tote"
                    value={binId}
                    ref={inputRef}
                    onChange={(e) => {
                      setBinId(e.target.value);
                    }}
                    onPressEnter={(event) => {
                      changeBin(event);
                    }}
                    disabled={toteInput}
                  />
                </div>
              </Styles.OrderCard>
            ) : (
              <Styles.OrderCard
                disable={qCount === selectedOrder?.quantity}
                increment={qCount > 0}
              >
                <Styles.ImgContainer className="left">
                  {selectedOrder?.imageUri && selectedOrder?.imageUri !== "" ? (
                    <img src={selectedOrder?.imageUri} alt="product image" />
                  ) : (
                    <ImageIcon />
                  )}
                </Styles.ImgContainer>
                <Styles.IDContainer>
                  <Styles.Labels>Item Name:</Styles.Labels>
                  <Tooltip
                    title={
                      selectedOrder?.productName &&
                      selectedOrder?.productName !== ""
                        ? selectedOrder?.productName
                        : ""
                    }
                  >
                    <Styles.ItemName className="description">
                      {selectedOrder?.productName &&
                      selectedOrder?.productName !== ""
                        ? selectedOrder?.productName
                        : ""}
                    </Styles.ItemName>
                  </Tooltip>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Styles.Labels>Description:</Styles.Labels>
                  <Tooltip title={selectedOrder?.description}>
                    <Styles.ItemName className="description">
                      {selectedOrder?.description}
                    </Styles.ItemName>
                  </Tooltip>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Styles.QContainer>
                    <div>
                      <Styles.Labels>Qty Count</Styles.Labels>
                      <Styles.ItemName className="count">
                        {selectedOrder?.selectedQty}
                      </Styles.ItemName>
                    </div>
                    <div>
                      <Styles.Labels>Qty Needed</Styles.Labels>
                      <Styles.ItemName className="need">
                        {selectedOrder?.quantity !== undefined &&
                        selectedOrder?.quantity < 10
                          ? ("0" + `${selectedOrder?.quantity}`).slice(-2)
                          : selectedOrder?.quantity}
                      </Styles.ItemName>
                    </div>
                    <div>
                      <Styles.Labels>Exp Date</Styles.Labels>
                      <Styles.ItemName className="exp">-NA-</Styles.ItemName>
                    </div>
                  </Styles.QContainer>
                </Styles.IDContainer>
                <Styles.IDContainer>
                  <Styles.Labels>SKU ID:</Styles.Labels>
                  <Styles.ItemName>{selectedOrder?.skuId}</Styles.ItemName>
                </Styles.IDContainer>
                <Styles.LeftButtonContainer>
                  <Button
                    className="dec-btn"
                    disabled={selectedOrder?.selectedQty == 0}
                    onClick={() => removeQty(qCount - 1)}
                  >
                    -
                  </Button>
                  <Button
                    className="inc-btn"
                    onClick={() => addQty(qCount + 1)}
                    disabled={qCount === selectedOrder?.quantity}
                  >
                    +
                  </Button>
                </Styles.LeftButtonContainer>
              </Styles.OrderCard>
            )}

            <Styles.OrderDetail doneDisabled={true}>
              <Styles.OrderContainer>
                {/* <Styles.Head>
                  <span>Pickup Tote</span>
                </Styles.Head> */}
                {/* <Styles.DropContainer>
                  <Styles.Drop disable={binId === "" ? true : false}>
                    {binId !== "" ? binId : "- NA - "}

                    <Styles.DropIcon>
                      <PickupIcon />
                    </Styles.DropIcon>
                  </Styles.Drop>
                </Styles.DropContainer>
                <Styles.OrderDivider /> */}
                <Styles.Head>
                  <span>Inward Station</span>
                </Styles.Head>
                <Styles.PickupContainer disable={okayLoading} state={firstRow.length}>
                  {firstRow.map((val: any, key: any) => {
                    const Index = itemsData.filter(
                      (item: any) => item.position === val
                    );
                    // const orderPlace = Object.values(
                    //   orderItems.inwardTask.from
                    // );
                    // const joinedItem = orderPlace.join(",");

                    return (
                      <>
                        {Index.length > 0 ? (
                          // Index[0].orderColor === "default" ? (
                          //   <Styles.Pickup
                          //     state={"default"}
                          //     className={
                          //       binId === "" || selectedOrder.length !== 0
                          //         ? "not highlight"
                          //         : "highlight"
                          //     }
                          //     onClick={() => onLocationMapping(Index[0])}
                          //   >
                          //     <div className="main">
                          //       <div>
                          //         <Styles.Drop
                          //           disable={binId === "" ? true : false}
                          //         >
                          //           {binId !== "" ? binId : "- NA - "}

                          //           <Styles.DropIcon>
                          //             <PickupIcon />
                          //           </Styles.DropIcon>
                          //         </Styles.Drop>
                          //       </div>

                          //       <div className="second">
                          //         {Index[0].loaderStatus === true ? (
                          //           <Styles.CardLoad>
                          //             <CardLoaderIcon />
                          //           </Styles.CardLoad>
                          //         ) : (
                          //           // <Styles.Station>
                          //           //   ID: B0{key + 1} Pick Station {key + 1}
                          //           // </Styles.Station>
                          //           <Styles.Station>
                          //             ID: B0{key + 1} Pick Station {key + 1}
                          //           </Styles.Station>
                          //         )}
                          //       </div>
                          //     </div>
                          //   </Styles.Pickup>
                          // ) : (
                          <Styles.Pickup
                            state={Index[0].orderColor}
                            onClick={() =>
                              Index[0].completedStatus === false &&
                              selectedOrder.length === 0 &&
                              onHandleOrderItem(Index)
                            }
                            //okDisable={Index[0].completedStatus}
                            okDisable={
                              Index[0].selectedQty > 0 &&
                              Index[0].completedStatus === false
                                ? false
                                : true
                            }
                          >
                            <Tooltip
                            overlayStyle={{ whiteSpace: 'pre-line' }}
                              title={renderTooltip(Index[0].inwardTask.skuId, Index[0].prodcutName)}
                              /* title={
                                Index[0].inwardTask.skuId &&
                                Index[0].inwardTask.skuId !== ""
                                  ? "Sku Id : " + Index[0].inwardTask.skuId + "       Product Name : " + Index[0].prodcutName
                                  : ""
                              } */
                              color="rgb(31, 84, 84)"
                            >
                            <div className="main">
                              <div>
                                {/* <Styles.DropContainer> */}
                                <Styles.Drop
                                  disable={
                                    Index[0].toteID !== "" || Index[0].completedStatus === true ? false : true
                                  }
                                >
                                  {Index[0].toteID !== ""
                                    ? Index[0].toteID
                                    : "- NA - "}

                                  <Styles.DropIcon>
                                    <PickupIcon />
                                  </Styles.DropIcon>
                                </Styles.Drop>
                                {/* </Styles.DropContainer> */}
                              </div>
                              <div className="second">
                                <Styles.PickImage>
                                  {Index[0].image ? (
                                    <img src={Index[0].image} alt="box image" />
                                  ) : (
                                    <ProductIcon />
                                  )}
                                </Styles.PickImage>
                                <Styles.PickIcon color={Index[0].orderColor}>
                                  <Styles.Qty state={Index[0].orderColor}>
                                    {Index[0].selectedQty}/{" "}
                                    {Index[0].inwardTask.quantity < 10
                                      ? (
                                          "0" +
                                          `${Index[0].inwardTask.quantity}`
                                        ).slice(-2)
                                      : Index[0].inwardTask.quantity}
                                  </Styles.Qty>
                                  {Index[0].orderColor === "completed" ? (
                                    Index[0].completedStatus === true ? (
                                      <TickIcon />
                                    ) : (
                                      <InfoIconWhite />
                                    )
                                  ) : Index[0].orderColor === "hold" ? (
                                    <HoldInfoIcon />
                                  ) : Index[0].orderColor === "progress" ? (
                                    <ProgressInfoIcon />
                                  ) : (
                                    <InfoIcon />
                                  )}

                                  <Button
                                    className="ok-btn"
                                    onClick={() => isOpenOkPopup(Index[0]?.id)}
                                    //disabled={Index[0].completedStatus}
                                    disabled={
                                      Index[0].selectedQty > 0 &&
                                      Index[0].completedStatus === false
                                        ? false
                                        : true
                                    }
                                  >
                                    {Index[0].okLoader === true ? (
                                  <LoadIcon />
                                ) : (
                                  "OK"
                                )}
                                  </Button>
                                </Styles.PickIcon>
                                
                              </div>
                              <div>
                              <Styles.DropSlotSKU
                                  disable={
                                    Index[0].toteID !== "" || Index[0].completedStatus === true ? false : true
                                  }
                                >
                                  SKU Id - {Index[0].inwardTask.skuId !== ""
                                    ? Index[0].inwardTask.skuId
                                    : "-"}
                                    </Styles.DropSlotSKU>
                              </div>
                              <div>
                                {/* <Styles.DropContainer> */}
                                <Styles.DropSlot
                                  disable={
                                    Index[0].toteID !== "" || Index[0].completedStatus === true ? false : true
                                  }
                                >
                                  Slot - {Index[0].position !== ""
                                    ? Index[0].position
                                    : "- NA - "}
                                  <Styles.DropIcon>
                                    <PickupIcon />
                                  </Styles.DropIcon>
                                </Styles.DropSlot>
                                {/* </Styles.DropContainer> */}
                              </div>
                            </div>
                            </Tooltip>
                          </Styles.Pickup>
                        ) : (
                          // )
                          <Styles.Pickup className="no-data"></Styles.Pickup>
                        )}
                      </>
                    );

                    return (
                      <>
                        {val === "" ? (
                          <Styles.Pickup
                            state={"default"}
                            className={
                              pickHighlight === false ? "highlight" : ""
                            }
                            onClick={() => onHandleOrderItem(orderItems)}
                          >
                            {pickHighlight === false ? (
                              <Styles.Station>
                                ID: B0{key + 1} Pick Station {key + 1}
                              </Styles.Station>
                            ) : (
                              <>
                                <Styles.PickImage>
                                  <ProductIcon />
                                </Styles.PickImage>
                                <Styles.PickIcon>
                                  <Styles.Qty>05 / 05</Styles.Qty>
                                  <InfoIconWhite />
                                </Styles.PickIcon>
                              </>
                            )}
                          </Styles.Pickup>
                        ) : (
                          <Styles.Pickup></Styles.Pickup>
                        )}
                      </>
                    );
                  })}
                </Styles.PickupContainer>
              </Styles.OrderContainer>
              <Styles.OrderBtnContainer>
                {/* {orderItems?.pickTask.orderReferenceId !== undefined &&
                  orderItems?.pickTask.binId !== undefined &&
                  orderItems?.pickTask.skuId !== undefined &&
                  orderItems?.pickTask.quantity && (
                    <Button
                      className="ok-btn"
                      onClick={() => getPickItemsData(orderItems?.id)}
                      loading={doneLoading}
                    >
                      {doneLoading === true ? "Loading..." : "Done"}
                    </Button>
                  )} */}
                <Button
                  className="done-btn"
                  loading={doneLoading}
                  onClick={() => onHandleDoneButton()}
                >
                  {doneLoading === true ? "Loading..." : "Done"}
                </Button>
              </Styles.OrderBtnContainer>
            </Styles.OrderDetail>
          </Styles.Content>
          <OkPopup
            visible={isOkPopup}
            isCloseOkPopup={isCloseOkPopup}
            isRestart={onHandleRestart}
            isCancel={isCancelOkPopup}
            errorMessage={errorMessage}
          />
        </>
      </Styles.Container>
      <>{contextHolder}</>
    </>
  );
}
